/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ContactSubmissionEntity } from '../models/contact-submission-entity';
@Injectable({
  providedIn: 'root',
})
class ContactSubmissionService extends __BaseService {
  static readonly getApiContactsubmissionPath = '/api/contactsubmission';
  static readonly postApiContactsubmissionPath = '/api/contactsubmission';
  static readonly putApiContactsubmissionUpdatecontactsubmissionasyncPath = '/api/contactsubmission/updatecontactsubmissionasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return Get All Contact Submissions
   */
  getApiContactsubmissionResponse(apiVersion?: string): __Observable<__StrictHttpResponse<ContactSubmissionEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/contactsubmission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContactSubmissionEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Contact Submissions
   */
  getApiContactsubmission(apiVersion?: string): __Observable<ContactSubmissionEntity> {
    return this.getApiContactsubmissionResponse(apiVersion).pipe(
      __map(_r => _r.body as ContactSubmissionEntity)
    );
  }

  /**
   * @param params The `ContactSubmissionService.PostApiContactsubmissionParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiContactsubmissionResponse(params: ContactSubmissionService.PostApiContactsubmissionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/contactsubmission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContactSubmissionService.PostApiContactsubmissionParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiContactsubmission(params: ContactSubmissionService.PostApiContactsubmissionParams): __Observable<null> {
    return this.postApiContactsubmissionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ContactSubmissionService.PutApiContactsubmissionUpdatecontactsubmissionasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiContactsubmissionUpdatecontactsubmissionasyncResponse(params: ContactSubmissionService.PutApiContactsubmissionUpdatecontactsubmissionasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/contactsubmission/updatecontactsubmissionasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ContactSubmissionService.PutApiContactsubmissionUpdatecontactsubmissionasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiContactsubmissionUpdatecontactsubmissionasync(params: ContactSubmissionService.PutApiContactsubmissionUpdatecontactsubmissionasyncParams): __Observable<null> {
    return this.putApiContactsubmissionUpdatecontactsubmissionasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ContactSubmissionService {

  /**
   * Parameters for postApiContactsubmission
   */
  export interface PostApiContactsubmissionParams {
    body?: ContactSubmissionEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiContactsubmissionUpdatecontactsubmissionasync
   */
  export interface PutApiContactsubmissionUpdatecontactsubmissionasyncParams {
    body?: ContactSubmissionEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { ContactSubmissionService }
