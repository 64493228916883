/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BoostCartDto } from '../models/boost-cart-dto';
import { CartEntity } from '../models/cart-entity';
@Injectable({
  providedIn: 'root',
})
class CartService extends __BaseService {
  static readonly getApiCartBuildboostdtosasyncPath = '/api/cart/buildboostdtosasync';
  static readonly getApiCartGetallcartsasyncPath = '/api/cart/getallcartsasync';
  static readonly getApiCartGetcartpostsbyuseridasyncUserIdPath = '/api/cart/getcartpostsbyuseridasync/{userId}';
  static readonly getApiCartGetcartbyuseridasyncUserIdPath = '/api/cart/getcartbyuseridasync/{userId}';
  static readonly getApiCartGetcartboostsbyuseridasyncUserIdPath = '/api/cart/getcartboostsbyuseridasync/{userId}';
  static readonly postApiCartAddcartasyncPath = '/api/cart/addcartasync';
  static readonly postApiCartUpdatecartasyncPath = '/api/cart/updatecartasync';
  static readonly postApiCartDeletecartasyncPath = '/api/cart/deletecartasync';
  static readonly deleteApiCartDeleteitemfromcartasyncPath = '/api/cart/deleteitemfromcartasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return Build Boost Dtos
   */
  getApiCartBuildboostdtosasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<BoostCartDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cart/buildboostdtosasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoostCartDto>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Build Boost Dtos
   */
  getApiCartBuildboostdtosasync(apiVersion?: string): __Observable<BoostCartDto> {
    return this.getApiCartBuildboostdtosasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as BoostCartDto)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Getting All Carts
   */
  getApiCartGetallcartsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<CartEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cart/getallcartsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CartEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Getting All Carts
   */
  getApiCartGetallcartsasync(apiVersion?: string): __Observable<CartEntity> {
    return this.getApiCartGetallcartsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as CartEntity)
    );
  }

  /**
   * @param params The `CartService.GetApiCartGetcartpostsbyuseridasyncUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Cart Posts
   */
  getApiCartGetcartpostsbyuseridasyncUserIdResponse(params: CartService.GetApiCartGetcartpostsbyuseridasyncUserIdParams): __Observable<__StrictHttpResponse<CartEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cart/getcartpostsbyuseridasync/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CartEntity>;
      })
    );
  }
  /**
   * @param params The `CartService.GetApiCartGetcartpostsbyuseridasyncUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Cart Posts
   */
  getApiCartGetcartpostsbyuseridasyncUserId(params: CartService.GetApiCartGetcartpostsbyuseridasyncUserIdParams): __Observable<CartEntity> {
    return this.getApiCartGetcartpostsbyuseridasyncUserIdResponse(params).pipe(
      __map(_r => _r.body as CartEntity)
    );
  }

  /**
   * @param params The `CartService.GetApiCartGetcartbyuseridasyncUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Cart
   */
  getApiCartGetcartbyuseridasyncUserIdResponse(params: CartService.GetApiCartGetcartbyuseridasyncUserIdParams): __Observable<__StrictHttpResponse<CartEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cart/getcartbyuseridasync/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CartEntity>;
      })
    );
  }
  /**
   * @param params The `CartService.GetApiCartGetcartbyuseridasyncUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Cart
   */
  getApiCartGetcartbyuseridasyncUserId(params: CartService.GetApiCartGetcartbyuseridasyncUserIdParams): __Observable<CartEntity> {
    return this.getApiCartGetcartbyuseridasyncUserIdResponse(params).pipe(
      __map(_r => _r.body as CartEntity)
    );
  }

  /**
   * @param params The `CartService.GetApiCartGetcartboostsbyuseridasyncUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `userGuid`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Cart Posts
   */
  getApiCartGetcartboostsbyuseridasyncUserIdResponse(params: CartService.GetApiCartGetcartboostsbyuseridasyncUserIdParams): __Observable<__StrictHttpResponse<CartEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.userGuid != null) __params = __params.set('userGuid', params.userGuid.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/cart/getcartboostsbyuseridasync/${params.userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CartEntity>;
      })
    );
  }
  /**
   * @param params The `CartService.GetApiCartGetcartboostsbyuseridasyncUserIdParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `userGuid`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Cart Posts
   */
  getApiCartGetcartboostsbyuseridasyncUserId(params: CartService.GetApiCartGetcartboostsbyuseridasyncUserIdParams): __Observable<CartEntity> {
    return this.getApiCartGetcartboostsbyuseridasyncUserIdResponse(params).pipe(
      __map(_r => _r.body as CartEntity)
    );
  }

  /**
   * @param params The `CartService.PostApiCartAddcartasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `boostId`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Adding New Cart
   */
  postApiCartAddcartasyncResponse(params: CartService.PostApiCartAddcartasyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.boostId != null) __params = __params.set('boostId', params.boostId.toString());
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cart/addcartasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `CartService.PostApiCartAddcartasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `boostId`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Adding New Cart
   */
  postApiCartAddcartasync(params: CartService.PostApiCartAddcartasyncParams): __Observable<string> {
    return this.postApiCartAddcartasyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `CartService.PostApiCartUpdatecartasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `boostId`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * - `addItem`:
   */
  postApiCartUpdatecartasyncResponse(params: CartService.PostApiCartUpdatecartasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.boostId != null) __params = __params.set('boostId', params.boostId.toString());
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.addItem != null) __params = __params.set('addItem', params.addItem.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cart/updatecartasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CartService.PostApiCartUpdatecartasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `boostId`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * - `addItem`:
   */
  postApiCartUpdatecartasync(params: CartService.PostApiCartUpdatecartasyncParams): __Observable<null> {
    return this.postApiCartUpdatecartasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CartService.PostApiCartDeletecartasyncParams` containing the following parameters:
   *
   * - `timeOut`:
   *
   * - `cartId`:
   *
   * - `api-version`: The requested API version
   */
  postApiCartDeletecartasyncResponse(params: CartService.PostApiCartDeletecartasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeOut != null) __params = __params.set('timeOut', params.timeOut.toString());
    if (params.cartId != null) __params = __params.set('cartId', params.cartId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/cart/deletecartasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CartService.PostApiCartDeletecartasyncParams` containing the following parameters:
   *
   * - `timeOut`:
   *
   * - `cartId`:
   *
   * - `api-version`: The requested API version
   */
  postApiCartDeletecartasync(params: CartService.PostApiCartDeletecartasyncParams): __Observable<null> {
    return this.postApiCartDeletecartasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CartService.DeleteApiCartDeleteitemfromcartasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `isPost`:
   *
   * - `boostId`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiCartDeleteitemfromcartasyncResponse(params: CartService.DeleteApiCartDeleteitemfromcartasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.isPost != null) __params = __params.set('isPost', params.isPost.toString());
    if (params.boostId != null) __params = __params.set('boostId', params.boostId.toString());
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/cart/deleteitemfromcartasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CartService.DeleteApiCartDeleteitemfromcartasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `isPost`:
   *
   * - `boostId`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiCartDeleteitemfromcartasync(params: CartService.DeleteApiCartDeleteitemfromcartasyncParams): __Observable<null> {
    return this.deleteApiCartDeleteitemfromcartasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CartService {

  /**
   * Parameters for getApiCartGetcartpostsbyuseridasyncUserId
   */
  export interface GetApiCartGetcartpostsbyuseridasyncUserIdParams {
    userId: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiCartGetcartbyuseridasyncUserId
   */
  export interface GetApiCartGetcartbyuseridasyncUserIdParams {
    userId: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiCartGetcartboostsbyuseridasyncUserId
   */
  export interface GetApiCartGetcartboostsbyuseridasyncUserIdParams {
    userId: string;
    userGuid?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiCartAddcartasync
   */
  export interface PostApiCartAddcartasyncParams {
    postId?: number;
    boostId?: number;
    body?: CartEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiCartUpdatecartasync
   */
  export interface PostApiCartUpdatecartasyncParams {
    postId?: number;
    boostId?: number;
    body?: CartEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
    addItem?: boolean;
  }

  /**
   * Parameters for postApiCartDeletecartasync
   */
  export interface PostApiCartDeletecartasyncParams {
    timeOut?: boolean;
    cartId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiCartDeleteitemfromcartasync
   */
  export interface DeleteApiCartDeleteitemfromcartasyncParams {
    postId?: number;
    isPost?: boolean;
    boostId?: number;
    body?: CartEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { CartService }
