import { environment } from 'environments/environment';
export class HelperFunctions {

    static getDomain(): string {
        return this.getApiEndpoint().rootUrl;
    }

    static getApiEndpoint() {        
        // If apiUrl is blank this means the application is running in production mode.
        return {
            rootUrl: environment.apiUrl !== '' ? environment.apiUrl : window.location.origin
        };
    }

    static getExternalEndpoint() {        
        // If apiUrl is blank this means the application is running in production mode.
        return {
            rootUrl: environment.apiExternal !== '' ? environment.apiExternal : window.location.origin
        };
    }

    static zeroPadding(inputNumber: number, size: number): string {
        let numberString = inputNumber+"";
        while (numberString.length < size) {
        numberString = "0" + numberString;
        }
        return numberString;
    }
}