/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ConfigurationEntity } from '../models/configuration-entity';
import { NotificationDto } from '../models/notification-dto';
@Injectable({
  providedIn: 'root',
})
class ConfigurationService extends __BaseService {
  static readonly getApiConfigurationPath = '/api/configuration';
  static readonly putApiConfigurationPath = '/api/configuration';
  static readonly getApiConfigurationGetlocationdataasyncPath = '/api/configuration/getlocationdataasync';
  static readonly getApiConfigurationGetcaptchasitekeyasyncPath = '/api/configuration/getcaptchasitekeyasync';
  static readonly postApiConfigurationVerifycaptchaasyncPath = '/api/configuration/verifycaptchaasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return The resource with the specified unique identifier.
   */
  getApiConfigurationResponse(apiVersion?: string): __Observable<__StrictHttpResponse<ConfigurationEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/configuration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConfigurationEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return The resource with the specified unique identifier.
   */
  getApiConfiguration(apiVersion?: string): __Observable<ConfigurationEntity> {
    return this.getApiConfigurationResponse(apiVersion).pipe(
      __map(_r => _r.body as ConfigurationEntity)
    );
  }

  /**
   * @param params The `ConfigurationService.PutApiConfigurationParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return The resource was updated.
   */
  putApiConfigurationResponse(params: ConfigurationService.PutApiConfigurationParams): __Observable<__StrictHttpResponse<NotificationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/configuration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationDto>;
      })
    );
  }
  /**
   * @param params The `ConfigurationService.PutApiConfigurationParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return The resource was updated.
   */
  putApiConfiguration(params: ConfigurationService.PutApiConfigurationParams): __Observable<NotificationDto> {
    return this.putApiConfigurationResponse(params).pipe(
      __map(_r => _r.body as NotificationDto)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Got default location data
   */
  getApiConfigurationGetlocationdataasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<ConfigurationEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/configuration/getlocationdataasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConfigurationEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Got default location data
   */
  getApiConfigurationGetlocationdataasync(apiVersion?: string): __Observable<ConfigurationEntity> {
    return this.getApiConfigurationGetlocationdataasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as ConfigurationEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return The captcha site key was retrieved
   */
  getApiConfigurationGetcaptchasitekeyasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/configuration/getcaptchasitekeyasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return The captcha site key was retrieved
   */
  getApiConfigurationGetcaptchasitekeyasync(apiVersion?: string): __Observable<string> {
    return this.getApiConfigurationGetcaptchasitekeyasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ConfigurationService.PostApiConfigurationVerifycaptchaasyncParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `api-version`: The requested API version
   *
   * @return The captcha data was verified
   */
  postApiConfigurationVerifycaptchaasyncResponse(params: ConfigurationService.PostApiConfigurationVerifycaptchaasyncParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __params = __params.set('token', params.token.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/configuration/verifycaptchaasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `ConfigurationService.PostApiConfigurationVerifycaptchaasyncParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `api-version`: The requested API version
   *
   * @return The captcha data was verified
   */
  postApiConfigurationVerifycaptchaasync(params: ConfigurationService.PostApiConfigurationVerifycaptchaasyncParams): __Observable<boolean> {
    return this.postApiConfigurationVerifycaptchaasyncResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module ConfigurationService {

  /**
   * Parameters for putApiConfiguration
   */
  export interface PutApiConfigurationParams {
    body?: Array<ConfigurationEntity>;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiConfigurationVerifycaptchaasync
   */
  export interface PostApiConfigurationVerifycaptchaasyncParams {
    token?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { ConfigurationService }
