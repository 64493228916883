<div class="app-container" fxLayout="column">
  <mat-toolbar color="primary" class="do-not-print" fxLayout="row" style="height:75px !important; background-color: #343a40 !important;">
    <span class="alexBrush" [routerLink]="[ '/main-page' ]" (click)="sidenav.close();" style="cursor:pointer;">W</span>
    <span class="span">
      <h1 class="appTitle alexBrush" style="letter-spacing:3px;">{{title}}</h1>
    </span>
    <button *ngIf="isLoggedIn()" mat-button (click)="navigateToCart()"><mat-icon>shopping_cart</mat-icon></button>
    <button mat-button (click)="sidenav.open()">
      <mat-icon *ngIf="isLoggedIn() && notificationBehaviourSubjectFromService !== 0" 
        [matBadge]="notificationBehaviourSubjectFromService" matBadgeColor="warn">menu</mat-icon>
      <mat-icon *ngIf="!isLoggedIn() || (isLoggedIn() && notificationBehaviourSubjectFromService === 0)">menu</mat-icon>
    </button>
  </mat-toolbar>
  <mat-sidenav-container fxFlex>
    <mat-sidenav #sidenav class="sideNav" position="end">
      <mat-nav-list class="list-nav">
        <mat-list-item [routerLink]="[ '/main-page' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>home</mat-icon>
          <h4 mat-line>Home</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list class="list-nav">
        <mat-list-item [routerLink]="[ '/search' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>search</mat-icon>
          <h4 mat-line>Global Search</h4>
        </mat-list-item>
      </mat-nav-list>
      <!-- <mat-nav-list class="list-nav">
        <mat-list-item [routerLink]="[ '/map' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>map</mat-icon>
          <h4 mat-line>Map</h4>
        </mat-list-item>
      </mat-nav-list> -->
      <!-- <mat-nav-list class="list-nav">
        <mat-list-item [routerLink]="[ '/how-it-works' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>description</mat-icon>
          <h4 mat-line>How It Works</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list class="list-nav">
        <mat-list-item [routerLink]="[ '/faq' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>contact_support</mat-icon>
          <h4 mat-line>FAQ</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list class="list-nav">
        <mat-list-item [routerLink]="[ '/contact' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>contact_page</mat-icon>
          <h4 mat-line>Contact</h4>
        </mat-list-item>
      </mat-nav-list> -->
      <mat-nav-list *ngIf="isLoggedIn()" class="list-nav">
        <mat-list-item [routerLink]="[ '/favorites' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>favorite</mat-icon>
          <h4 mat-line>My Favorites</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list *ngIf="isLoggedIn()" class="list-nav">
        <mat-list-item [routerLink]="[ '/account' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>account_circle</mat-icon>
          <h4 mat-line *ngIf="notificationBehaviourSubjectFromService === 0">My Account</h4>
          <h4 mat-line *ngIf="notificationBehaviourSubjectFromService !== 0" style="padding: 10px 0px"><span [matBadge]="notificationBehaviourSubjectFromService" matBadgeOverlap="false" matBadgeColor="warn">My Account</span></h4>
          <!-- TODO: Add notification tracker here %***% -->
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list *ngIf="isLoggedIn() && isUserAdmin()" class="list-nav">
        <mat-list-item [routerLink]="[ '/users' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>supervisor_account</mat-icon>
          <h4 mat-line>User Management</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list *ngIf="isLoggedIn() && isUserAdmin()" class="list-nav">
        <mat-list-item [routerLink]="[ '/abuse-manager' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>privacy_tip</mat-icon>
          <h4 mat-line>Abuse Management</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list *ngIf="isLoggedIn() && isUserAdmin()" class="list-nav">
        <mat-list-item [routerLink]="[ '/configuration' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>admin_panel_settings</mat-icon>
          <h4 mat-line>App Settings</h4>
        </mat-list-item>
      </mat-nav-list>
      <!-- <mat-nav-list *ngIf="isLoggedIn() && isUserAdmin()" class="list-nav">
        <mat-list-item [routerLink]="[ '/swagger' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>api</mat-icon>
          <h4 mat-line>Swagger</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list *ngIf="isLoggedIn() && isUserAdmin()" class="list-nav">
        <mat-list-item [routerLink]="[ '/hangfire' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>dashboard</mat-icon>
          <h4 mat-line>Hangfire</h4>
        </mat-list-item>
      </mat-nav-list> -->
      <mat-nav-list *ngIf="isLoggedIn()" class="list-nav">
        <mat-list-item (click)="logout();sidenav.close();">
          <mat-icon mat-list-icon>logout</mat-icon>
          <h4 mat-line>Logout</h4>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list *ngIf="!isLoggedIn()" class="list-nav">
        <mat-list-item [routerLink]="[ '/login-register' ]" (click)="sidenav.close();">
          <mat-icon mat-list-icon>login</mat-icon>
          <h4 mat-line>Login / Register</h4>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <app-footer *ngIf="isMobile"></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <ion-footer *ngIf="isMobile">
    <ion-toolbar>
        <ion-tab-bar>
          <ion-tab-button [routerLink]="[ '/main-page' ]">
            <ion-icon name="home"></ion-icon>
            <ion-label>Home</ion-label>
            <!-- <ion-badge>6</ion-badge> -->
          </ion-tab-button>

          <ion-tab-button [routerLink]="[ '/search' ]">
            <ion-icon name="search"></ion-icon>
            <ion-label>Search</ion-label>
          </ion-tab-button>

          <!-- <ion-tab-button [routerLink]="[ '/map' ]">
            <ion-icon name="map"></ion-icon>
            <ion-label>Map</ion-label>
          </ion-tab-button> -->

          <ion-tab-button *ngIf="isLoggedIn()" [routerLink]="[ '/favorites' ]">
            <ion-icon name="heart"></ion-icon>
            <ion-label>Favorites</ion-label>
            <!-- <ion-badge>6</ion-badge> -->
          </ion-tab-button>

          <ion-tab-button *ngIf="isLoggedIn()" [routerLink]="[ '/account' ]">
            <ion-icon name="person-circle"></ion-icon>
            <ion-label>Account</ion-label>
            <!-- <ion-badge>6</ion-badge> -->
          </ion-tab-button>

          <ion-tab-button *ngIf="!isLoggedIn()" [routerLink]="[ '/login-register' ]">
            <ion-icon name="globe-outline"></ion-icon>
            <ion-label>Login</ion-label>
          </ion-tab-button>

          <ion-tab-button *ngIf="isLoggedIn()" (click)="sidenav.open()">
            <ion-icon ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>
            <ion-label>More</ion-label>
            <!-- <ion-badge>6</ion-badge> -->
          </ion-tab-button>

        </ion-tab-bar>
    </ion-toolbar>
  </ion-footer>
  <app-footer *ngIf="!isMobile"></app-footer>
</div>

