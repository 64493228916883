import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AppConfig {

  private _config: { [key: string]: any };

  constructor() {

    this._config = {
      SnackBarDuration: 5000,
      HorizontalPosition: 'right',
      VerticalPosition: 'bottom'
    };
  }

  get setting(): { [key: string]: any } {

    return this._config;
  }

  get(key: any) {

    return this._config[key];
  }
}
