export class LocalStorageKeys {

    static JwtExpiryKey: string = "WOW_JWT_Expiration";
    static RefreshExpiryKey: string = "WOW_Refresh_Expiration";
    static IsUserLoggedIn: string = "WOW_Is_User_Logged_In";
    static IsUserAdmin: string = "WOW_Is_User_Admin";
    static IsUserSuperAdmin: string = "WOW_Is_User_Super_Admin";
    static userGuid: string = "WOW_User_Guid";
    static IsUserGuest: string = "WOW_Is_User_Guest";

}