/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostTypeEntity } from '../models/post-type-entity';
import { FilterEntity } from '../models/filter-entity';
import { SearchEntity } from '../models/search-entity';
@Injectable({
  providedIn: 'root',
})
class PostTypeService extends __BaseService {
  static readonly getApiPosttypeGetallsubposttypesasyncPath = '/api/posttype/getallsubposttypesasync';
  static readonly getApiPosttypeGetallposttypesasyncPath = '/api/posttype/getallposttypesasync';
  static readonly getApiPosttypeGetnearbyposttypesasyncPath = '/api/posttype/getnearbyposttypesasync';
  static readonly getApiPosttypeGetactiveposttypesasyncPath = '/api/posttype/getactiveposttypesasync';
  static readonly getApiPosttypeGettopposttypesasyncPath = '/api/posttype/gettopposttypesasync';
  static readonly getApiPosttypeGetposttypebyidasyncPath = '/api/posttype/getposttypebyidasync';
  static readonly getApiPosttypeGetfiltersbycategoryidPath = '/api/posttype/getfiltersbycategoryid';
  static readonly postApiPosttypeGetfiltersbysearchobjectasyncPath = '/api/posttype/getfiltersbysearchobjectasync';
  static readonly getApiPosttypeGetuserposttypebyidasyncPath = '/api/posttype/getuserposttypebyidasync';
  static readonly getApiPosttypeGetfavoritesbyuseridasyncPath = '/api/posttype/getfavoritesbyuseridasync';
  static readonly getApiPosttypeAddfavoritecategoryasyncPath = '/api/posttype/addfavoritecategoryasync';
  static readonly getApiPosttypeRemovefavoritecategoryasyncPath = '/api/posttype/removefavoritecategoryasync';
  static readonly postApiPosttypeAddposttypeasyncPath = '/api/posttype/addposttypeasync';
  static readonly putApiPosttypeUpdateposttypeasyncPath = '/api/posttype/updateposttypeasync';
  static readonly putApiPosttypeTogglenotificationsforfavoriteasyncPath = '/api/posttype/togglenotificationsforfavoriteasync';
  static readonly deleteApiPosttypeDeleteposttypeasyncPath = '/api/posttype/deleteposttypeasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PostTypeService.GetApiPosttypeGetallsubposttypesasyncParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get All Sub Types
   */
  getApiPosttypeGetallsubposttypesasyncResponse(params: PostTypeService.GetApiPosttypeGetallsubposttypesasyncParams): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getallsubposttypesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.GetApiPosttypeGetallsubposttypesasyncParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get All Sub Types
   */
  getApiPosttypeGetallsubposttypesasync(params: PostTypeService.GetApiPosttypeGetallsubposttypesasyncParams): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGetallsubposttypesasyncResponse(params).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Post Types
   */
  getApiPosttypeGetallposttypesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getallposttypesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Post Types
   */
  getApiPosttypeGetallposttypesasync(apiVersion?: string): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGetallposttypesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get Nearby Post Types
   */
  getApiPosttypeGetnearbyposttypesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getnearbyposttypesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get Nearby Post Types
   */
  getApiPosttypeGetnearbyposttypesasync(apiVersion?: string): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGetnearbyposttypesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get Active Post Types
   */
  getApiPosttypeGetactiveposttypesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getactiveposttypesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get Active Post Types
   */
  getApiPosttypeGetactiveposttypesasync(apiVersion?: string): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGetactiveposttypesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get Top Post Types
   */
  getApiPosttypeGettopposttypesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/gettopposttypesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get Top Post Types
   */
  getApiPosttypeGettopposttypesasync(apiVersion?: string): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGettopposttypesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param params The `PostTypeService.GetApiPosttypeGetposttypebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post Type By ID
   */
  getApiPosttypeGetposttypebyidasyncResponse(params: PostTypeService.GetApiPosttypeGetposttypebyidasyncParams): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getposttypebyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.GetApiPosttypeGetposttypebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post Type By ID
   */
  getApiPosttypeGetposttypebyidasync(params: PostTypeService.GetApiPosttypeGetposttypebyidasyncParams): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGetposttypebyidasyncResponse(params).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param params The `PostTypeService.GetApiPosttypeGetfiltersbycategoryidParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Filters By Post Type ID
   */
  getApiPosttypeGetfiltersbycategoryidResponse(params: PostTypeService.GetApiPosttypeGetfiltersbycategoryidParams): __Observable<__StrictHttpResponse<FilterEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getfiltersbycategoryid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FilterEntity>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.GetApiPosttypeGetfiltersbycategoryidParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Filters By Post Type ID
   */
  getApiPosttypeGetfiltersbycategoryid(params: PostTypeService.GetApiPosttypeGetfiltersbycategoryidParams): __Observable<FilterEntity> {
    return this.getApiPosttypeGetfiltersbycategoryidResponse(params).pipe(
      __map(_r => _r.body as FilterEntity)
    );
  }

  /**
   * @param params The `PostTypeService.PostApiPosttypeGetfiltersbysearchobjectasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Filters By SearchObject
   */
  postApiPosttypeGetfiltersbysearchobjectasyncResponse(params: PostTypeService.PostApiPosttypeGetfiltersbysearchobjectasyncParams): __Observable<__StrictHttpResponse<FilterEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/posttype/getfiltersbysearchobjectasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FilterEntity>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.PostApiPosttypeGetfiltersbysearchobjectasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Filters By SearchObject
   */
  postApiPosttypeGetfiltersbysearchobjectasync(params: PostTypeService.PostApiPosttypeGetfiltersbysearchobjectasyncParams): __Observable<FilterEntity> {
    return this.postApiPosttypeGetfiltersbysearchobjectasyncResponse(params).pipe(
      __map(_r => _r.body as FilterEntity)
    );
  }

  /**
   * @param params The `PostTypeService.GetApiPosttypeGetuserposttypebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get User Post Type By ID
   */
  getApiPosttypeGetuserposttypebyidasyncResponse(params: PostTypeService.GetApiPosttypeGetuserposttypebyidasyncParams): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getuserposttypebyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.GetApiPosttypeGetuserposttypebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get User Post Type By ID
   */
  getApiPosttypeGetuserposttypebyidasync(params: PostTypeService.GetApiPosttypeGetuserposttypebyidasyncParams): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGetuserposttypebyidasyncResponse(params).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get User Post Type Favorites By ID
   */
  getApiPosttypeGetfavoritesbyuseridasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/getfavoritesbyuseridasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get User Post Type Favorites By ID
   */
  getApiPosttypeGetfavoritesbyuseridasync(apiVersion?: string): __Observable<PostTypeEntity> {
    return this.getApiPosttypeGetfavoritesbyuseridasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param params The `PostTypeService.GetApiPosttypeAddfavoritecategoryasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPosttypeAddfavoritecategoryasyncResponse(params: PostTypeService.GetApiPosttypeAddfavoritecategoryasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/addfavoritecategoryasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.GetApiPosttypeAddfavoritecategoryasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPosttypeAddfavoritecategoryasync(params: PostTypeService.GetApiPosttypeAddfavoritecategoryasyncParams): __Observable<null> {
    return this.getApiPosttypeAddfavoritecategoryasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostTypeService.GetApiPosttypeRemovefavoritecategoryasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPosttypeRemovefavoritecategoryasyncResponse(params: PostTypeService.GetApiPosttypeRemovefavoritecategoryasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/posttype/removefavoritecategoryasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.GetApiPosttypeRemovefavoritecategoryasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPosttypeRemovefavoritecategoryasync(params: PostTypeService.GetApiPosttypeRemovefavoritecategoryasyncParams): __Observable<null> {
    return this.getApiPosttypeRemovefavoritecategoryasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostTypeService.PostApiPosttypeAddposttypeasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New Post Type
   */
  postApiPosttypeAddposttypeasyncResponse(params: PostTypeService.PostApiPosttypeAddposttypeasyncParams): __Observable<__StrictHttpResponse<PostTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/posttype/addposttypeasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeEntity>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.PostApiPosttypeAddposttypeasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New Post Type
   */
  postApiPosttypeAddposttypeasync(params: PostTypeService.PostApiPosttypeAddposttypeasyncParams): __Observable<PostTypeEntity> {
    return this.postApiPosttypeAddposttypeasyncResponse(params).pipe(
      __map(_r => _r.body as PostTypeEntity)
    );
  }

  /**
   * @param params The `PostTypeService.PutApiPosttypeUpdateposttypeasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiPosttypeUpdateposttypeasyncResponse(params: PostTypeService.PutApiPosttypeUpdateposttypeasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/posttype/updateposttypeasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.PutApiPosttypeUpdateposttypeasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiPosttypeUpdateposttypeasync(params: PostTypeService.PutApiPosttypeUpdateposttypeasyncParams): __Observable<null> {
    return this.putApiPosttypeUpdateposttypeasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostTypeService.PutApiPosttypeTogglenotificationsforfavoriteasyncParams` containing the following parameters:
   *
   * - `categoryId`:
   *
   * - `api-version`: The requested API version
   */
  putApiPosttypeTogglenotificationsforfavoriteasyncResponse(params: PostTypeService.PutApiPosttypeTogglenotificationsforfavoriteasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.categoryId != null) __params = __params.set('categoryId', params.categoryId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/posttype/togglenotificationsforfavoriteasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.PutApiPosttypeTogglenotificationsforfavoriteasyncParams` containing the following parameters:
   *
   * - `categoryId`:
   *
   * - `api-version`: The requested API version
   */
  putApiPosttypeTogglenotificationsforfavoriteasync(params: PostTypeService.PutApiPosttypeTogglenotificationsforfavoriteasyncParams): __Observable<null> {
    return this.putApiPosttypeTogglenotificationsforfavoriteasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostTypeService.DeleteApiPosttypeDeleteposttypeasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiPosttypeDeleteposttypeasyncResponse(params: PostTypeService.DeleteApiPosttypeDeleteposttypeasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/posttype/deleteposttypeasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostTypeService.DeleteApiPosttypeDeleteposttypeasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiPosttypeDeleteposttypeasync(params: PostTypeService.DeleteApiPosttypeDeleteposttypeasyncParams): __Observable<null> {
    return this.deleteApiPosttypeDeleteposttypeasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PostTypeService {

  /**
   * Parameters for getApiPosttypeGetallsubposttypesasync
   */
  export interface GetApiPosttypeGetallsubposttypesasyncParams {
    name?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPosttypeGetposttypebyidasync
   */
  export interface GetApiPosttypeGetposttypebyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPosttypeGetfiltersbycategoryid
   */
  export interface GetApiPosttypeGetfiltersbycategoryidParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPosttypeGetfiltersbysearchobjectasync
   */
  export interface PostApiPosttypeGetfiltersbysearchobjectasyncParams {
    body?: SearchEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPosttypeGetuserposttypebyidasync
   */
  export interface GetApiPosttypeGetuserposttypebyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPosttypeAddfavoritecategoryasync
   */
  export interface GetApiPosttypeAddfavoritecategoryasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPosttypeRemovefavoritecategoryasync
   */
  export interface GetApiPosttypeRemovefavoritecategoryasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPosttypeAddposttypeasync
   */
  export interface PostApiPosttypeAddposttypeasyncParams {
    body?: PostTypeEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiPosttypeUpdateposttypeasync
   */
  export interface PutApiPosttypeUpdateposttypeasyncParams {
    body?: PostTypeEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiPosttypeTogglenotificationsforfavoriteasync
   */
  export interface PutApiPosttypeTogglenotificationsforfavoriteasyncParams {
    categoryId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiPosttypeDeleteposttypeasync
   */
  export interface DeleteApiPosttypeDeleteposttypeasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { PostTypeService }
