/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReceiptEntity } from '../models/receipt-entity';
import { ReceiptManagerDto } from '../models/receipt-manager-dto';
import { ReceiptViewDto } from '../models/receipt-view-dto';
@Injectable({
  providedIn: 'root',
})
class ReceiptService extends __BaseService {
  static readonly getApiReceiptGetallreceiptsasyncPath = '/api/receipt/getallreceiptsasync';
  static readonly getApiReceiptGetreceiptsbydateasyncPath = '/api/receipt/getreceiptsbydateasync';
  static readonly getApiReceiptGetreceiptbyidasyncPath = '/api/receipt/getreceiptbyidasync';
  static readonly getApiReceiptGetreceiptsbyuseridasyncPath = '/api/receipt/getreceiptsbyuseridasync';
  static readonly postApiReceiptAddnewreceiptasyncPath = '/api/receipt/addnewreceiptasync';
  static readonly deleteApiReceiptDeletereceiptasyncPath = '/api/receipt/deletereceiptasync';
  static readonly postApiReceiptBuildreceiptmanagerdtoasyncPath = '/api/receipt/buildreceiptmanagerdtoasync';
  static readonly postApiReceiptBuildreceiptviewasyncPath = '/api/receipt/buildreceiptviewasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return Retrieved Receipt Data
   */
  getApiReceiptGetallreceiptsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<ReceiptEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/receipt/getallreceiptsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReceiptEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Retrieved Receipt Data
   */
  getApiReceiptGetallreceiptsasync(apiVersion?: string): __Observable<ReceiptEntity> {
    return this.getApiReceiptGetallreceiptsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as ReceiptEntity)
    );
  }

  /**
   * @param params The `ReceiptService.GetApiReceiptGetreceiptsbydateasyncParams` containing the following parameters:
   *
   * - `date`:
   *
   * - `api-version`: The requested API version
   */
  getApiReceiptGetreceiptsbydateasyncResponse(params: ReceiptService.GetApiReceiptGetreceiptsbydateasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/receipt/getreceiptsbydateasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReceiptService.GetApiReceiptGetreceiptsbydateasyncParams` containing the following parameters:
   *
   * - `date`:
   *
   * - `api-version`: The requested API version
   */
  getApiReceiptGetreceiptsbydateasync(params: ReceiptService.GetApiReceiptGetreceiptsbydateasyncParams): __Observable<null> {
    return this.getApiReceiptGetreceiptsbydateasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ReceiptService.GetApiReceiptGetreceiptbyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiReceiptGetreceiptbyidasyncResponse(params: ReceiptService.GetApiReceiptGetreceiptbyidasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/receipt/getreceiptbyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReceiptService.GetApiReceiptGetreceiptbyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiReceiptGetreceiptbyidasync(params: ReceiptService.GetApiReceiptGetreceiptbyidasyncParams): __Observable<null> {
    return this.getApiReceiptGetreceiptbyidasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ReceiptService.GetApiReceiptGetreceiptsbyuseridasyncParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   */
  getApiReceiptGetreceiptsbyuseridasyncResponse(params: ReceiptService.GetApiReceiptGetreceiptsbyuseridasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/receipt/getreceiptsbyuseridasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReceiptService.GetApiReceiptGetreceiptsbyuseridasyncParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `api-version`: The requested API version
   */
  getApiReceiptGetreceiptsbyuseridasync(params: ReceiptService.GetApiReceiptGetreceiptsbyuseridasyncParams): __Observable<null> {
    return this.getApiReceiptGetreceiptsbyuseridasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ReceiptService.PostApiReceiptAddnewreceiptasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Added New Receipt
   */
  postApiReceiptAddnewreceiptasyncResponse(params: ReceiptService.PostApiReceiptAddnewreceiptasyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/receipt/addnewreceiptasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ReceiptService.PostApiReceiptAddnewreceiptasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Added New Receipt
   */
  postApiReceiptAddnewreceiptasync(params: ReceiptService.PostApiReceiptAddnewreceiptasyncParams): __Observable<string> {
    return this.postApiReceiptAddnewreceiptasyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ReceiptService.DeleteApiReceiptDeletereceiptasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiReceiptDeletereceiptasyncResponse(params: ReceiptService.DeleteApiReceiptDeletereceiptasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/receipt/deletereceiptasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReceiptService.DeleteApiReceiptDeletereceiptasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiReceiptDeletereceiptasync(params: ReceiptService.DeleteApiReceiptDeletereceiptasyncParams): __Observable<null> {
    return this.deleteApiReceiptDeletereceiptasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Create Table Entry
   */
  postApiReceiptBuildreceiptmanagerdtoasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<ReceiptManagerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/receipt/buildreceiptmanagerdtoasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReceiptManagerDto>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Create Table Entry
   */
  postApiReceiptBuildreceiptmanagerdtoasync(apiVersion?: string): __Observable<ReceiptManagerDto> {
    return this.postApiReceiptBuildreceiptmanagerdtoasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as ReceiptManagerDto)
    );
  }

  /**
   * @param params The `ReceiptService.PostApiReceiptBuildreceiptviewasyncParams` containing the following parameters:
   *
   * - `receiptId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Build Receipt View
   */
  postApiReceiptBuildreceiptviewasyncResponse(params: ReceiptService.PostApiReceiptBuildreceiptviewasyncParams): __Observable<__StrictHttpResponse<ReceiptViewDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.receiptId != null) __params = __params.set('receiptId', params.receiptId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/receipt/buildreceiptviewasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReceiptViewDto>;
      })
    );
  }
  /**
   * @param params The `ReceiptService.PostApiReceiptBuildreceiptviewasyncParams` containing the following parameters:
   *
   * - `receiptId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Build Receipt View
   */
  postApiReceiptBuildreceiptviewasync(params: ReceiptService.PostApiReceiptBuildreceiptviewasyncParams): __Observable<ReceiptViewDto> {
    return this.postApiReceiptBuildreceiptviewasyncResponse(params).pipe(
      __map(_r => _r.body as ReceiptViewDto)
    );
  }
}

module ReceiptService {

  /**
   * Parameters for getApiReceiptGetreceiptsbydateasync
   */
  export interface GetApiReceiptGetreceiptsbydateasyncParams {
    date?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiReceiptGetreceiptbyidasync
   */
  export interface GetApiReceiptGetreceiptbyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiReceiptGetreceiptsbyuseridasync
   */
  export interface GetApiReceiptGetreceiptsbyuseridasyncParams {
    userId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiReceiptAddnewreceiptasync
   */
  export interface PostApiReceiptAddnewreceiptasyncParams {
    body?: ReceiptEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiReceiptDeletereceiptasync
   */
  export interface DeleteApiReceiptDeletereceiptasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiReceiptBuildreceiptviewasync
   */
  export interface PostApiReceiptBuildreceiptviewasyncParams {
    receiptId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { ReceiptService }
