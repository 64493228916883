import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/api/services';
import { LocalStorageKeys } from 'app/constants';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class RefreshCredentialsService {
    constructor(private router: Router, private userService: UserService) {}

    public async sendAuthorizeRequest(requestAction: () => any): Promise<void> {
        let jwtExpiration = localStorage.getItem(LocalStorageKeys.JwtExpiryKey);
        let refreshExpiration = localStorage.getItem(LocalStorageKeys.RefreshExpiryKey);

        if (jwtExpiration !== null && refreshExpiration !== null) {
            // Check within 10 seconds of expiry to ensure the cookie doesn't get removed before the request is sent out.
            let jwtExpDate = moment(jwtExpiration).add(-10, 'seconds');
            let refreshExpDate = moment(refreshExpiration).add(-10, 'seconds');
            let today = moment(new Date()).utc(); // The serve has the expiry dates in utc.

            // Jwt expired.
            if (jwtExpDate <= today) {
                // Refresh token not expired.
                if (refreshExpDate > today) {
                    await this.userService
                        .getApiUserRefreshtokenasync()
                        .toPromise()
                        .then(tokenData => {
                            localStorage.setItem(LocalStorageKeys.JwtExpiryKey, tokenData.jwtTokenExpiry);
                            localStorage.setItem(LocalStorageKeys.RefreshExpiryKey, tokenData.refreshTokenExpiry);
                            localStorage.setItem(LocalStorageKeys.IsUserAdmin, tokenData.isAdmin.toString());
                            localStorage.setItem(LocalStorageKeys.IsUserAdmin, tokenData.isSuperAdmin.toString());
                            localStorage.setItem(LocalStorageKeys.userGuid, tokenData.userGuid);
                            requestAction();
                        })
                        .catch(() => {
                            // Refresh token expired or guid for the user is not available.
                            this.router.navigate(['/login-register']);
                        });
                }
                // Refresh token expired.
                else {
                    this.router.navigate(['/login-register']);
                }
            }
            else {
                requestAction();
            }
        }
        else {
            this.router.navigate(['/login-register']);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserAuthenticationService {
    constructor(private router: Router, private userService: UserService) {}

    public async checkIfUserIsStillLoggedIn(): Promise<Boolean> {
        let jwtExpiration = localStorage.getItem(LocalStorageKeys.JwtExpiryKey);
        let refreshExpiration = localStorage.getItem(LocalStorageKeys.RefreshExpiryKey);

        if (jwtExpiration !== null && refreshExpiration !== null) {
            // Check within 10 seconds of expiry to ensure the cookie doesn't get removed before the request is sent out.
            let jwtExpDate = moment(jwtExpiration).add(-10, 'seconds');
            let refreshExpDate = moment(refreshExpiration).add(-10, 'seconds');
            let today = moment(new Date()).utc(); // The serve has the expiry dates in utc.

            // Jwt expired.
            if (jwtExpDate <= today) {
                // Refresh token not expired.
                if (refreshExpDate > today) {
                    await this.userService
                        .getApiUserRefreshtokenasync()
                        .toPromise()
                        .then(tokenData => {
                            localStorage.setItem(LocalStorageKeys.JwtExpiryKey, tokenData.jwtTokenExpiry);
                            localStorage.setItem(LocalStorageKeys.RefreshExpiryKey, tokenData.refreshTokenExpiry);
                            localStorage.setItem(LocalStorageKeys.IsUserAdmin, tokenData.isAdmin.toString());
                            localStorage.setItem(LocalStorageKeys.IsUserAdmin, tokenData.isSuperAdmin.toString());
                            localStorage.setItem(LocalStorageKeys.userGuid, tokenData.userGuid);

                            return true;
                        })
                        .catch(() => {
                            // Refresh token expired or guid for the user is not available.
                            return false;
                        });

                    return true;

                }
                // Refresh token expired.
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    }
}
