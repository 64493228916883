import { AppConfig } from '../config/config';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionTypes } from 'app/api/models';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'app/components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private appConfig: AppConfig,
    private router: Router, private _snackBar: MatSnackBar) { }

  // Make this a shared service.
  public displaySnackbar(message: string, action: ActionTypes = ActionTypes.INFO) {

    let colorClass = null;
    let title = null;    

    // The case check names must match the server side ActionTypes enum string name.
    // Classes found in the styles.scss file.
    switch (action) {
      case ActionTypes.SUCCESS: 
        colorClass = 'snackbar-success';
        title = 'Success!';
        break;
      case ActionTypes.INFO: 
        colorClass = 'snackbar-info';
        title = 'Heads up!';
        break;
      case ActionTypes.WARNING: 
        colorClass = 'snackbar-warning';
        title = 'Warning!';
        break;
      case ActionTypes.ERROR: 
       colorClass = 'snackbar-error';
        title = 'Error!'; 
        break;
      case ActionTypes.REDIRECT: 
        colorClass = 'snackbar-error';
        title = 'Error!'; 
        break;
      default: colorClass = ''
        break;
    }

    if(action !== ActionTypes.REDIRECT) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          title: title,
          html: message,
          duration: this.appConfig.setting['SnackBarDuration']
        },
        duration: this.appConfig.setting['SnackBarDuration'],
        horizontalPosition: this.appConfig.setting['HorizontalPosition'],
        verticalPosition: this.appConfig.setting['VerticalPosition'],
        panelClass: colorClass
      });
    }
    else {
      this._snackBar.open(message, 'Go To Page', {
        duration: this.appConfig.setting['SnackBarDuration'],
        horizontalPosition: this.appConfig.setting['HorizontalPosition'],
        verticalPosition: this.appConfig.setting['VerticalPosition'],
        panelClass: colorClass
      }).onAction().subscribe(()=>{
        this.router.navigate(['/login-register']);
      });
    }

  }

  public displayLongSnackbar(message: string, action: ActionTypes = ActionTypes.INFO) {
    let colorClass = null;
    let title = null;    

    // The case check names must match the server side ActionTypes enum string name.
    // Classes found in the styles.scss file.
    switch (action) {
      case ActionTypes.SUCCESS: 
        colorClass = 'snackbar-success';
        title = 'Success!';
        break;
      case ActionTypes.INFO: 
        colorClass = 'snackbar-info';
        title = 'Heads up!';
        break;
      case ActionTypes.WARNING: 
        colorClass = 'snackbar-warning';
        title = 'Warning!';
        break;
      case ActionTypes.ERROR: 
       colorClass = 'snackbar-error';
        title = 'Error!'; 
        break;
      case ActionTypes.REDIRECT: 
        colorClass = 'snackbar-error';
        title = 'Error!'; 
        break;
      default: colorClass = ''
        break;
    }

    if(action !== ActionTypes.REDIRECT) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          title: title,
          html: message,
          duration: 10000
        },
        duration: 10000,
        horizontalPosition: this.appConfig.setting['HorizontalPosition'],
        verticalPosition: this.appConfig.setting['VerticalPosition'],
        panelClass: colorClass
      });
    }
    else {
      this._snackBar.open(message, 'Go To Page', {
        duration: 10000,
        horizontalPosition: this.appConfig.setting['HorizontalPosition'],
        verticalPosition: this.appConfig.setting['VerticalPosition'],
        panelClass: colorClass
      }).onAction().subscribe(()=>{
        this.router.navigate(['/login-register']);
      });
    }

  }

  public displayRedirectSnackbar(statusText: string, message: string = 'Oops, something went wrong.') {
    this.displaySnackbar(`${message} ${statusText}.`, ActionTypes.REDIRECT);
  }

  public displayErrorSnackbar(statusText: string, message: string = 'Oops, something went wrong.') {
    this.displaySnackbar(`${message} ${statusText}.`, ActionTypes.ERROR);
  }
  public displayLongErrorSnackbar(statusText: string, message: string = 'Oops, something went wrong.') {
    this.displayLongSnackbar(`${message} ${statusText}.`, ActionTypes.ERROR);
  }

  public snackbarFormIncomplete(statusText: string, message: string = 'One or more forms are incorrect.') {
    this.displaySnackbar(`${message} ${statusText}.`, ActionTypes.ERROR);
  }
}
