/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PayPalDataEntity } from '../models/pay-pal-data-entity';
@Injectable({
  providedIn: 'root',
})
class PayPalCartService extends __BaseService {
  static readonly postApiPaypalcartPostinfotoserverasyncPath = '/api/paypalcart/postinfotoserverasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PayPalCartService.PostApiPaypalcartPostinfotoserverasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return PayPal Order Complete
   */
  postApiPaypalcartPostinfotoserverasyncResponse(params: PayPalCartService.PostApiPaypalcartPostinfotoserverasyncParams): __Observable<__StrictHttpResponse<PayPalDataEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/paypalcart/postinfotoserverasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PayPalDataEntity>;
      })
    );
  }
  /**
   * @param params The `PayPalCartService.PostApiPaypalcartPostinfotoserverasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return PayPal Order Complete
   */
  postApiPaypalcartPostinfotoserverasync(params: PayPalCartService.PostApiPaypalcartPostinfotoserverasyncParams): __Observable<PayPalDataEntity> {
    return this.postApiPaypalcartPostinfotoserverasyncResponse(params).pipe(
      __map(_r => _r.body as PayPalDataEntity)
    );
  }
}

module PayPalCartService {

  /**
   * Parameters for postApiPaypalcartPostinfotoserverasync
   */
  export interface PostApiPaypalcartPostinfotoserverasyncParams {
    body?: PayPalDataEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { PayPalCartService }
