import { Component, OnInit } from '@angular/core';
import { VersionService } from 'app/api/services/version.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  versionNumber: string = '0.0.0';

  constructor(
    private versionService: VersionService
  ) { }

  async ngOnInit() {
    this.versionNumber = await this.versionService.getApiVersion().toPromise();
  }

}
