/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NotificationEntity } from '../models/notification-entity';
@Injectable({
  providedIn: 'root',
})
class NotificationService extends __BaseService {
  static readonly deleteApiNotificationPath = '/api/notification';
  static readonly getApiNotificationPath = '/api/notification';
  static readonly putApiNotificationMarknotificationasreadasyncPath = '/api/notification/marknotificationasreadasync';
  static readonly putApiNotificationMarknotificationasunreadasyncPath = '/api/notification/marknotificationasunreadasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `NotificationService.DeleteApiNotificationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiNotificationResponse(params: NotificationService.DeleteApiNotificationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/notification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `NotificationService.DeleteApiNotificationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiNotification(params: NotificationService.DeleteApiNotificationParams): __Observable<null> {
    return this.deleteApiNotificationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Getting Notifications for User
   */
  getApiNotificationResponse(apiVersion?: string): __Observable<__StrictHttpResponse<NotificationEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/notification`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Getting Notifications for User
   */
  getApiNotification(apiVersion?: string): __Observable<NotificationEntity> {
    return this.getApiNotificationResponse(apiVersion).pipe(
      __map(_r => _r.body as NotificationEntity)
    );
  }

  /**
   * @param params The `NotificationService.PutApiNotificationMarknotificationasreadasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  putApiNotificationMarknotificationasreadasyncResponse(params: NotificationService.PutApiNotificationMarknotificationasreadasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/notification/marknotificationasreadasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `NotificationService.PutApiNotificationMarknotificationasreadasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  putApiNotificationMarknotificationasreadasync(params: NotificationService.PutApiNotificationMarknotificationasreadasyncParams): __Observable<null> {
    return this.putApiNotificationMarknotificationasreadasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `NotificationService.PutApiNotificationMarknotificationasunreadasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  putApiNotificationMarknotificationasunreadasyncResponse(params: NotificationService.PutApiNotificationMarknotificationasunreadasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/notification/marknotificationasunreadasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `NotificationService.PutApiNotificationMarknotificationasunreadasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  putApiNotificationMarknotificationasunreadasync(params: NotificationService.PutApiNotificationMarknotificationasunreadasyncParams): __Observable<null> {
    return this.putApiNotificationMarknotificationasunreadasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NotificationService {

  /**
   * Parameters for deleteApiNotification
   */
  export interface DeleteApiNotificationParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiNotificationMarknotificationasreadasync
   */
  export interface PutApiNotificationMarknotificationasreadasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiNotificationMarknotificationasunreadasync
   */
  export interface PutApiNotificationMarknotificationasunreadasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { NotificationService }
