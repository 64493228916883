import { Injectable, OnDestroy } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { SnackBarService } from './snackbar.service';
import { ConfigDto, AppInitService } from '../ApplicationInit/app-init.service';
import { Platform } from '@ionic/angular';
import { RefreshCredentialsService as AuthorizedRequestService } from 'app/services/authorized-request.service';
import { NotificationService } from 'app/api/services';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NotificationEntity } from 'app/api/models';
import { takeUntil } from 'rxjs/operators';
import {  BehaviorSubject, Observable, Subject } from 'rxjs';
import { LiveMessengerService } from './live-messenger.service';

@Injectable({
  providedIn: 'root'
})
export class LiveNotificationService implements OnDestroy {

  private c: ConfigDto;
  private hubConnection: signalR.HubConnection;
  public unreadNotifications: number;
  public isLoading: boolean;
  public notifications: NotificationEntity[];
  private unsubscribe = new Subject();
  private notificationBehaviourSubject = new BehaviorSubject(0);

  constructor(public snackBarService: SnackBarService,
              private liveMessengerService: LiveMessengerService,
              private authorizedRequestService: AuthorizedRequestService,
              private router: Router,
              public notificationService: NotificationService,
              public platform: Platform,
              private config: AppInitService) {
      this.isLoading = false;
      this.unreadNotifications = 0;
      config.loadConfigurations().pipe(takeUntil(this.unsubscribe)).subscribe(a => {
        this.c = a;
      });
  }

  public startConnection = () => {
    this.config.loadConfigurations().pipe(takeUntil(this.unsubscribe)).subscribe(a => {
      this.c = a;
      if (this.c !== undefined) {
        if (this.platform.is('ios') || this.platform.is('android')) {
          this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(this.c.signalrUrlExternal + '/live-notification')
          .configureLogging(signalR.LogLevel.Debug)
          .build();
        } else {
          this.hubConnection = new signalR.HubConnectionBuilder()
          .withUrl(this.c.signalrUrl + '/live-notification')
          .configureLogging(signalR.LogLevel.Debug)
          .build();
        }

        this.hubConnection.serverTimeoutInMilliseconds = 10000000000000;

        // this shouldnt just restart when closed
        this.hubConnection.onclose(() => setTimeout(() => this.stopSignalRConnection(), 2000));
        this.hubConnection.on('sendNotificationToUser', (data) => {
          this.snackBarService.displaySnackbar(data);
          this.GetNotifications();
          this.GetNotifications(); 
        });

        this.hubConnection.on('sendMessageToUser', (data) => {
          if (data.sendLiveNotification) {
            this.snackBarService.displaySnackbar(data.message);
          }
          if (this.liveMessengerService.isUserOnMessagesPage) {
            if (this.liveMessengerService.activeConvoId !== undefined
              && this.liveMessengerService.activeConvoId !== null
              && this.liveMessengerService.activeConvoId !== 0
              && this.liveMessengerService.activeConvoId === data.conversationId) {
                this.liveMessengerService.OpenConvo(data.conversationId);
            } else {
              this.liveMessengerService.GetConvos();
            }
          }
          this.getMessagesAndNotifications();
          //this.GetNotifications();
        });

        this.startSignalRConnection();

      }
    });

  }

  public startSignalRConnection() {
    this.hubConnection
    .start()
    .then(() => console.log('Connection started'))
    .then(() => this.GetNotifications())
    .catch(err => console.log('Error while starting connection: ' + err));
  }

  public stopSignalRConnection() {
    this.hubConnection
    .stop()
    .then(() => console.log('Connection stopped'))
    .catch(err => console.log('Error while stopped connection: ' + err));
  }

  public async GetNotifications() {
    const request = () => {
      this.notificationService.getApiNotification().pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        this.notifications = data;
        this.isLoading = false;
        if (data.length) {
          const result = data.filter(obj => {
            return obj.isRead === false;
          });
          this.setNotificationBehaviourSubject(result.length)
          return this.unreadNotifications = result.length;
        } else {
          this.setNotificationBehaviourSubject(0)
          return this.unreadNotifications = 0;
        }
      });
    };

    await this.authorizedRequestService.sendAuthorizeRequest(request);
  }

  public ShowDateOrDisplayNA(date: Date) {
    if (date !== null && date !== undefined && date !== new Date()) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    } else {
      return '';
    }
  }

  public async markAsRead(notification: NotificationEntity) {
    notification.isRead = true;
    const request = () => {
      this.notificationService
        .putApiNotificationMarknotificationasreadasync({ id: notification.id }).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
          this.unreadNotifications--;
          this.setNotificationBehaviourSubject(this.unreadNotifications)

      });
    };

    await this.authorizedRequestService.sendAuthorizeRequest(request);
  }

  public async markAsUnread(notification: NotificationEntity) {
    notification.isRead = false;
    const request = () => {
      this.notificationService
        .putApiNotificationMarknotificationasunreadasync({ id: notification.id })
        .pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        this.unreadNotifications++;
        this.setNotificationBehaviourSubject(this.unreadNotifications)
      });
    };

    await this.authorizedRequestService.sendAuthorizeRequest(request);
  }

  public async goToLink(notification: NotificationEntity) {
    if (!notification.isRead) {
      this.unreadNotifications--;
      this.setNotificationBehaviourSubject(this.unreadNotifications)
      notification.isRead = true;
      const request = () => {
        this.notificationService
          .putApiNotificationMarknotificationasreadasync({ id: notification.id })
          .pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
          this.router.navigate([notification.link]);
        });
      };

      await this.authorizedRequestService.sendAuthorizeRequest(request);
    } else {
      this.router.navigate([notification.link]);
    }
  }

  public async markAsDeleted(notification: NotificationEntity) {
    if (!notification.isRead) {
      this.unreadNotifications--;
      this.setNotificationBehaviourSubject(this.unreadNotifications)
    }
    const request = () => {
      this.notificationService.deleteApiNotification({id: notification.id}).pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
        this.GetNotifications();
      });
    };

    await this.authorizedRequestService.sendAuthorizeRequest(request);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public getMessagesAndNotifications(){
    this.liveMessengerService.GetMessages(this.liveMessengerService.activeConvoId);
    this.GetNotifications();
  }

  getNotificationBehaviourSubject(): Observable<number> { //getInfo
    return this.notificationBehaviourSubject.asObservable();
  }

  getNotificationBehaviourSubjectValue(): number {
    return this.notificationBehaviourSubject.getValue();
  }

  setNotificationBehaviourSubject(val: number) {
    this.notificationBehaviourSubject.next(val);
  }

}
