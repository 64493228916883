/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserEntity } from '../models/user-entity';
import { TokenData } from '../models/token-data';
import { UserProfileEntity } from '../models/user-profile-entity';
import { ResetPasswordEntity } from '../models/reset-password-entity';
import { FavoriteEntity } from '../models/favorite-entity';
import { RatingEntity } from '../models/rating-entity';
import { UserPostRatingDto } from '../models/user-post-rating-dto';
import { SelectionEntity } from '../models/selection-entity';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly postApiUserRegisteruserasyncPath = '/api/user/registeruserasync';
  static readonly postApiUserLoginuserasyncPath = '/api/user/loginuserasync';
  static readonly getApiUserRefreshtokenasyncPath = '/api/user/refreshtokenasync';
  static readonly postApiUserLoguseroutasyncPath = '/api/user/loguseroutasync';
  static readonly getApiUserGetuserprofilebyidasyncPath = '/api/user/getuserprofilebyidasync';
  static readonly getApiUserGetallusertypesasyncPath = '/api/user/getallusertypesasync';
  static readonly putApiUserUpdateuserprofileasyncPath = '/api/user/updateuserprofileasync';
  static readonly getApiUserGetuserprofilesasyncPath = '/api/user/getuserprofilesasync';
  static readonly getApiUserGetuserbyguidasyncPath = '/api/user/getuserbyguidasync';
  static readonly getApiUserGetuserbyidasyncPath = '/api/user/getuserbyidasync';
  static readonly getApiUserCheckifemailisinuseasyncPath = '/api/user/checkifemailisinuseasync';
  static readonly getApiUserVerifyuserasyncPath = '/api/user/verifyuserasync';
  static readonly postApiUserResetpasswordasyncPath = '/api/user/resetpasswordasync';
  static readonly getApiUserSendpasswordresetasyncPath = '/api/user/sendpasswordresetasync';
  static readonly postApiUserFavoriteorunfavoriteuserasyncPath = '/api/user/favoriteorunfavoriteuserasync';
  static readonly getApiUserGetaverageprofileratingasyncPath = '/api/user/getaverageprofileratingasync';
  static readonly postApiUserAddpostratingasyncPath = '/api/user/addpostratingasync';
  static readonly postApiUserCheckifuserprofileratedasyncPath = '/api/user/checkifuserprofileratedasync';
  static readonly getApiUserGetdaysasyncPath = '/api/user/getdaysasync';
  static readonly putApiUserTogglenotificationsforfavoriteasyncPath = '/api/user/togglenotificationsforfavoriteasync';
  static readonly postApiUserCheckifuserfavoritedpostownerasyncPath = '/api/user/checkifuserfavoritedpostownerasync';
  static readonly getApiUserGetusersbysearchasyncPath = '/api/user/getusersbysearchasync';
  static readonly getApiUserGetfavoriteusersbyuseridasyncPath = '/api/user/getfavoriteusersbyuseridasync';
  static readonly getApiUserAdduserprofileasyncPath = '/api/user/adduserprofileasync';
  static readonly getApiUserGetallusersasyncPath = '/api/user/getallusersasync';
  static readonly getApiUserGetallboostedrealtoruserprofilesPath = '/api/user/getallboostedrealtoruserprofiles';
  static readonly getApiUserGetallrealtorprofilesPath = '/api/user/getallrealtorprofiles';
  static readonly putApiUserUpdateuserasyncPath = '/api/user/updateuserasync';
  static readonly postApiUserReferafriendasyncPath = '/api/user/referafriendasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserService.PostApiUserRegisteruserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserRegisteruserasyncResponse(params: UserService.PostApiUserRegisteruserasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/registeruserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserRegisteruserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserRegisteruserasync(params: UserService.PostApiUserRegisteruserasyncParams): __Observable<null> {
    return this.postApiUserRegisteruserasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.PostApiUserLoginuserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Logging In User
   */
  postApiUserLoginuserasyncResponse(params: UserService.PostApiUserLoginuserasyncParams): __Observable<__StrictHttpResponse<TokenData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/loginuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenData>;
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserLoginuserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Logging In User
   */
  postApiUserLoginuserasync(params: UserService.PostApiUserLoginuserasyncParams): __Observable<TokenData> {
    return this.postApiUserLoginuserasyncResponse(params).pipe(
      __map(_r => _r.body as TokenData)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Refreshing Token
   */
  getApiUserRefreshtokenasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<TokenData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/refreshtokenasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TokenData>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Refreshing Token
   */
  getApiUserRefreshtokenasync(apiVersion?: string): __Observable<TokenData> {
    return this.getApiUserRefreshtokenasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as TokenData)
    );
  }

  /**
   * @param api-version The requested API version
   */
  postApiUserLoguseroutasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/loguseroutasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   */
  postApiUserLoguseroutasync(apiVersion?: string): __Observable<null> {
    return this.postApiUserLoguseroutasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.GetApiUserGetuserprofilebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Profile By Id
   */
  getApiUserGetuserprofilebyidasyncResponse(params: UserService.GetApiUserGetuserprofilebyidasyncParams): __Observable<__StrictHttpResponse<UserProfileEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getuserprofilebyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileEntity>;
      })
    );
  }
  /**
   * @param params The `UserService.GetApiUserGetuserprofilebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Profile By Id
   */
  getApiUserGetuserprofilebyidasync(params: UserService.GetApiUserGetuserprofilebyidasyncParams): __Observable<UserProfileEntity> {
    return this.getApiUserGetuserprofilebyidasyncResponse(params).pipe(
      __map(_r => _r.body as UserProfileEntity)
    );
  }

  /**
   * @param api-version The requested API version
   */
  getApiUserGetallusertypesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getallusertypesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   */
  getApiUserGetallusertypesasync(apiVersion?: string): __Observable<null> {
    return this.getApiUserGetallusertypesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.PutApiUserUpdateuserprofileasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiUserUpdateuserprofileasyncResponse(params: UserService.PutApiUserUpdateuserprofileasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/user/updateuserprofileasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.PutApiUserUpdateuserprofileasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiUserUpdateuserprofileasync(params: UserService.PutApiUserUpdateuserprofileasyncParams): __Observable<null> {
    return this.putApiUserUpdateuserprofileasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get Users Profiles
   */
  getApiUserGetuserprofilesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<UserProfileEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getuserprofilesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get Users Profiles
   */
  getApiUserGetuserprofilesasync(apiVersion?: string): __Observable<UserProfileEntity> {
    return this.getApiUserGetuserprofilesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as UserProfileEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Getting User
   */
  getApiUserGetuserbyguidasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<UserEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getuserbyguidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Getting User
   */
  getApiUserGetuserbyguidasync(apiVersion?: string): __Observable<UserEntity> {
    return this.getApiUserGetuserbyguidasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as UserEntity)
    );
  }

  /**
   * @param params The `UserService.GetApiUserGetuserbyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting User
   */
  getApiUserGetuserbyidasyncResponse(params: UserService.GetApiUserGetuserbyidasyncParams): __Observable<__StrictHttpResponse<UserEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getuserbyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserEntity>;
      })
    );
  }
  /**
   * @param params The `UserService.GetApiUserGetuserbyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting User
   */
  getApiUserGetuserbyidasync(params: UserService.GetApiUserGetuserbyidasyncParams): __Observable<UserEntity> {
    return this.getApiUserGetuserbyidasyncResponse(params).pipe(
      __map(_r => _r.body as UserEntity)
    );
  }

  /**
   * @param params The `UserService.GetApiUserCheckifemailisinuseasyncParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `api-version`: The requested API version
   */
  getApiUserCheckifemailisinuseasyncResponse(params: UserService.GetApiUserCheckifemailisinuseasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/checkifemailisinuseasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.GetApiUserCheckifemailisinuseasyncParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `api-version`: The requested API version
   */
  getApiUserCheckifemailisinuseasync(params: UserService.GetApiUserCheckifemailisinuseasyncParams): __Observable<null> {
    return this.getApiUserCheckifemailisinuseasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.GetApiUserVerifyuserasyncParams` containing the following parameters:
   *
   * - `guid`:
   *
   * - `api-version`: The requested API version
   */
  getApiUserVerifyuserasyncResponse(params: UserService.GetApiUserVerifyuserasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.guid != null) __params = __params.set('guid', params.guid.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/verifyuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.GetApiUserVerifyuserasyncParams` containing the following parameters:
   *
   * - `guid`:
   *
   * - `api-version`: The requested API version
   */
  getApiUserVerifyuserasync(params: UserService.GetApiUserVerifyuserasyncParams): __Observable<null> {
    return this.getApiUserVerifyuserasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.PostApiUserResetpasswordasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserResetpasswordasyncResponse(params: UserService.PostApiUserResetpasswordasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/resetpasswordasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserResetpasswordasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserResetpasswordasync(params: UserService.PostApiUserResetpasswordasyncParams): __Observable<null> {
    return this.postApiUserResetpasswordasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.GetApiUserSendpasswordresetasyncParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `api-version`: The requested API version
   */
  getApiUserSendpasswordresetasyncResponse(params: UserService.GetApiUserSendpasswordresetasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/sendpasswordresetasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.GetApiUserSendpasswordresetasyncParams` containing the following parameters:
   *
   * - `email`:
   *
   * - `api-version`: The requested API version
   */
  getApiUserSendpasswordresetasync(params: UserService.GetApiUserSendpasswordresetasyncParams): __Observable<null> {
    return this.getApiUserSendpasswordresetasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.PostApiUserFavoriteorunfavoriteuserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserFavoriteorunfavoriteuserasyncResponse(params: UserService.PostApiUserFavoriteorunfavoriteuserasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/favoriteorunfavoriteuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserFavoriteorunfavoriteuserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserFavoriteorunfavoriteuserasync(params: UserService.PostApiUserFavoriteorunfavoriteuserasyncParams): __Observable<null> {
    return this.postApiUserFavoriteorunfavoriteuserasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.GetApiUserGetaverageprofileratingasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Average Profile Rating
   */
  getApiUserGetaverageprofileratingasyncResponse(params: UserService.GetApiUserGetaverageprofileratingasyncParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getaverageprofileratingasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `UserService.GetApiUserGetaverageprofileratingasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Average Profile Rating
   */
  getApiUserGetaverageprofileratingasync(params: UserService.GetApiUserGetaverageprofileratingasyncParams): __Observable<number> {
    return this.getApiUserGetaverageprofileratingasyncResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `UserService.PostApiUserAddpostratingasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserAddpostratingasyncResponse(params: UserService.PostApiUserAddpostratingasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/addpostratingasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserAddpostratingasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserAddpostratingasync(params: UserService.PostApiUserAddpostratingasyncParams): __Observable<null> {
    return this.postApiUserAddpostratingasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.PostApiUserCheckifuserprofileratedasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Check if user has rated profile.
   */
  postApiUserCheckifuserprofileratedasyncResponse(params: UserService.PostApiUserCheckifuserprofileratedasyncParams): __Observable<__StrictHttpResponse<UserPostRatingDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/checkifuserprofileratedasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPostRatingDto>;
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserCheckifuserprofileratedasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Check if user has rated profile.
   */
  postApiUserCheckifuserprofileratedasync(params: UserService.PostApiUserCheckifuserprofileratedasyncParams): __Observable<UserPostRatingDto> {
    return this.postApiUserCheckifuserprofileratedasyncResponse(params).pipe(
      __map(_r => _r.body as UserPostRatingDto)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get Days
   */
  getApiUserGetdaysasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<SelectionEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getdaysasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SelectionEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get Days
   */
  getApiUserGetdaysasync(apiVersion?: string): __Observable<SelectionEntity> {
    return this.getApiUserGetdaysasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as SelectionEntity)
    );
  }

  /**
   * @param params The `UserService.PutApiUserTogglenotificationsforfavoriteasyncParams` containing the following parameters:
   *
   * - `favoritedUserId`:
   *
   * - `api-version`: The requested API version
   */
  putApiUserTogglenotificationsforfavoriteasyncResponse(params: UserService.PutApiUserTogglenotificationsforfavoriteasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.favoritedUserId != null) __params = __params.set('favoritedUserId', params.favoritedUserId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/user/togglenotificationsforfavoriteasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.PutApiUserTogglenotificationsforfavoriteasyncParams` containing the following parameters:
   *
   * - `favoritedUserId`:
   *
   * - `api-version`: The requested API version
   */
  putApiUserTogglenotificationsforfavoriteasync(params: UserService.PutApiUserTogglenotificationsforfavoriteasyncParams): __Observable<null> {
    return this.putApiUserTogglenotificationsforfavoriteasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.PostApiUserCheckifuserfavoritedpostownerasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Checking If User Favorited Post Owner
   */
  postApiUserCheckifuserfavoritedpostownerasyncResponse(params: UserService.PostApiUserCheckifuserfavoritedpostownerasyncParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/checkifuserfavoritedpostownerasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserCheckifuserfavoritedpostownerasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Checking If User Favorited Post Owner
   */
  postApiUserCheckifuserfavoritedpostownerasync(params: UserService.PostApiUserCheckifuserfavoritedpostownerasyncParams): __Observable<boolean> {
    return this.postApiUserCheckifuserfavoritedpostownerasyncResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `UserService.GetApiUserGetusersbysearchasyncParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Users By Search
   */
  getApiUserGetusersbysearchasyncResponse(params: UserService.GetApiUserGetusersbysearchasyncParams): __Observable<__StrictHttpResponse<UserEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getusersbysearchasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserEntity>;
      })
    );
  }
  /**
   * @param params The `UserService.GetApiUserGetusersbysearchasyncParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Users By Search
   */
  getApiUserGetusersbysearchasync(params: UserService.GetApiUserGetusersbysearchasyncParams): __Observable<UserEntity> {
    return this.getApiUserGetusersbysearchasyncResponse(params).pipe(
      __map(_r => _r.body as UserEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get Favorite Users
   */
  getApiUserGetfavoriteusersbyuseridasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<UserEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getfavoriteusersbyuseridasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get Favorite Users
   */
  getApiUserGetfavoriteusersbyuseridasync(apiVersion?: string): __Observable<UserEntity> {
    return this.getApiUserGetfavoriteusersbyuseridasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as UserEntity)
    );
  }

  /**
   * @param api-version The requested API version
   */
  getApiUserAdduserprofileasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/adduserprofileasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   */
  getApiUserAdduserprofileasync(apiVersion?: string): __Observable<null> {
    return this.getApiUserAdduserprofileasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Users
   */
  getApiUserGetallusersasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<UserEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getallusersasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Users
   */
  getApiUserGetallusersasync(apiVersion?: string): __Observable<UserEntity> {
    return this.getApiUserGetallusersasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as UserEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Boosted Realtor Profiles
   */
  getApiUserGetallboostedrealtoruserprofilesResponse(apiVersion?: string): __Observable<__StrictHttpResponse<UserProfileEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getallboostedrealtoruserprofiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Boosted Realtor Profiles
   */
  getApiUserGetallboostedrealtoruserprofiles(apiVersion?: string): __Observable<UserProfileEntity> {
    return this.getApiUserGetallboostedrealtoruserprofilesResponse(apiVersion).pipe(
      __map(_r => _r.body as UserProfileEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Realtor Profiles
   */
  getApiUserGetallrealtorprofilesResponse(apiVersion?: string): __Observable<__StrictHttpResponse<UserProfileEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/user/getallrealtorprofiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserProfileEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Realtor Profiles
   */
  getApiUserGetallrealtorprofiles(apiVersion?: string): __Observable<UserProfileEntity> {
    return this.getApiUserGetallrealtorprofilesResponse(apiVersion).pipe(
      __map(_r => _r.body as UserProfileEntity)
    );
  }

  /**
   * @param params The `UserService.PutApiUserUpdateuserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Updating User
   */
  putApiUserUpdateuserasyncResponse(params: UserService.PutApiUserUpdateuserasyncParams): __Observable<__StrictHttpResponse<UserEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/user/updateuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserEntity>;
      })
    );
  }
  /**
   * @param params The `UserService.PutApiUserUpdateuserasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Updating User
   */
  putApiUserUpdateuserasync(params: UserService.PutApiUserUpdateuserasyncParams): __Observable<UserEntity> {
    return this.putApiUserUpdateuserasyncResponse(params).pipe(
      __map(_r => _r.body as UserEntity)
    );
  }

  /**
   * @param params The `UserService.PostApiUserReferafriendasyncParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `email`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserReferafriendasyncResponse(params: UserService.PostApiUserReferafriendasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/user/referafriendasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.PostApiUserReferafriendasyncParams` containing the following parameters:
   *
   * - `phone`:
   *
   * - `email`:
   *
   * - `api-version`: The requested API version
   */
  postApiUserReferafriendasync(params: UserService.PostApiUserReferafriendasyncParams): __Observable<null> {
    return this.postApiUserReferafriendasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {

  /**
   * Parameters for postApiUserRegisteruserasync
   */
  export interface PostApiUserRegisteruserasyncParams {
    body?: UserEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiUserLoginuserasync
   */
  export interface PostApiUserLoginuserasyncParams {
    body?: UserEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiUserGetuserprofilebyidasync
   */
  export interface GetApiUserGetuserprofilebyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiUserUpdateuserprofileasync
   */
  export interface PutApiUserUpdateuserprofileasyncParams {
    body?: UserProfileEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiUserGetuserbyidasync
   */
  export interface GetApiUserGetuserbyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiUserCheckifemailisinuseasync
   */
  export interface GetApiUserCheckifemailisinuseasyncParams {
    email?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiUserVerifyuserasync
   */
  export interface GetApiUserVerifyuserasyncParams {
    guid?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiUserResetpasswordasync
   */
  export interface PostApiUserResetpasswordasyncParams {
    body?: ResetPasswordEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiUserSendpasswordresetasync
   */
  export interface GetApiUserSendpasswordresetasyncParams {
    email?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiUserFavoriteorunfavoriteuserasync
   */
  export interface PostApiUserFavoriteorunfavoriteuserasyncParams {
    body?: FavoriteEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiUserGetaverageprofileratingasync
   */
  export interface GetApiUserGetaverageprofileratingasyncParams {
    profileId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiUserAddpostratingasync
   */
  export interface PostApiUserAddpostratingasyncParams {
    body?: RatingEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiUserCheckifuserprofileratedasync
   */
  export interface PostApiUserCheckifuserprofileratedasyncParams {
    body?: RatingEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiUserTogglenotificationsforfavoriteasync
   */
  export interface PutApiUserTogglenotificationsforfavoriteasyncParams {
    favoritedUserId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiUserCheckifuserfavoritedpostownerasync
   */
  export interface PostApiUserCheckifuserfavoritedpostownerasyncParams {
    body?: FavoriteEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiUserGetusersbysearchasync
   */
  export interface GetApiUserGetusersbysearchasyncParams {
    search?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiUserUpdateuserasync
   */
  export interface PutApiUserUpdateuserasyncParams {
    body?: UserEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiUserReferafriendasync
   */
  export interface PostApiUserReferafriendasyncParams {
    phone?: string;
    email?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { UserService }
