import { BrowserModule, Meta } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing.module';
import { SnackBarService } from './services/snackbar.service';
import { SecurityService } from './services/security.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './layouts/app.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { SplashLayoutComponent } from './layouts/splash-layout/splash-layout.component';
import { LoadingSpinnerComponent } from './components/shared/loading-spinner/loading-spinner/loading-spinner.component';
import { ErrorViewComponent } from './components/shared/error-view/error-view/error-view.component';
import { environment } from 'environments/environment';
import { AppInitService } from './ApplicationInit/app-init.service';
import { ApiInterceptor } from './interceptor/api.intercepter';
import { Api } from './api-alias';
import { RedirectGuard } from './helpers/redirect-guard';
import { IonicModule } from '@ionic/angular';
import { UserManagerComponent } from './components/user-manager/user-manager.component';
import { CommonModule } from '@angular/common';
import { LocatorComponent } from './components/locator/locator.component';
import { HelperFunctions } from './helpers/functions.helper';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { AdvancedLocatorComponent } from './components/advanced-locator/advanced-locator.component';
import { IconPickerModule } from 'ngx-icon-picker';
import { MatBadgeModule, MatButtonModule, MatCardModule, MatFormFieldModule, MatIconModule, MatIconRegistry, MatInputModule, MatListModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSidenavModule, MatSnackBarModule, MatSortModule, MatTableModule, MatToolbarModule } from '@angular/material';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { LiveNotificationService } from './services/live-notification.service';
import { LiveMessengerService } from './services/live-messenger.service';
import { DisqusModule } from 'ngx-disqus';
import { DISQUS_SHORTNAME } from 'ngx-disqus';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ErrorComponent } from './components/error/error.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AgmCoreModule } from '@agm/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { FooterComponent } from './components/footer/footer.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
// import { DisableAfterClickDirective } from './directives/disable-after-click.directive.directive';

// For Root
export function getApiEndpoint() {
  return {
    // If apiUrl is blank this means the application is running in production mode.
    rootUrl: environment.apiUrl !== '' ? environment.apiUrl : window.location.origin
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    SplashLayoutComponent,
    UserManagerComponent,
    ErrorComponent,
    NotfoundComponent,
    UnauthorizedComponent,
    LocatorComponent,
    AdvancedLocatorComponent,
    LoadingSpinnerComponent,
    ErrorViewComponent,
    FooterComponent,
    // DisableAfterClickDirective
  ],
  exports: [
    CommonModule
  ],
  imports: [
    DisqusModule.forRoot('disqus_shortname'),
    BrowserModule,
    IconPickerModule,
    NgxImageGalleryModule,
    ShareIconsModule,
    MatIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    IvyCarouselModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,

    MatBadgeModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,

    Api.forRoot(HelperFunctions.getApiEndpoint()),
    // Api.forRoot(HelperFunctions.getExternalEndpoint()),
    IonicModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot('G-SV5FE77VR6'),
    NgxGoogleAnalyticsRouterModule,
    AgmCoreModule.forRoot({
      apiVersion: '3.31',
      apiKey: 'AIzaSyAnfF8n8fgMhhmTwjVvdQA-qo3BIPrSYoU',
      libraries: ['places']

    })
  ],
  providers: [
    MatIconRegistry,
    RedirectGuard,
    Geolocation,
    NativeGeocoder,
    SnackBarService,
    SecurityService,
    LiveNotificationService,
    LiveMessengerService,
    Meta,
    { provide: DISQUS_SHORTNAME, useValue: 'whoofferswhat-dev' },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: AppInitService) => () => configService.loadConfigurations().toPromise(),
      deps: [AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]

})
export class AppModule {

  constructor(public matIconRegistry: MatIconRegistry,
              private securityService: SecurityService,
              // tslint:disable-next-line: no-shadowed-variable
              private LiveNotificationService: LiveNotificationService) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    if (this.securityService.isUserLoggedIn()) {
      this.LiveNotificationService.startConnection();
    }
  }

 }
