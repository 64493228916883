/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserConversationEntity } from '../models/user-conversation-entity';
import { MessageEntity } from '../models/message-entity';
@Injectable({
  providedIn: 'root',
})
class ChatService extends __BaseService {
  static readonly getApiChatStartconversationasyncPath = '/api/chat/startconversationasync';
  static readonly getApiChatSendmessageasyncPath = '/api/chat/sendmessageasync';
  static readonly getApiChatGetconversationsforuserasyncPath = '/api/chat/getconversationsforuserasync';
  static readonly getApiChatGetmessagesbyconversationasyncPath = '/api/chat/getmessagesbyconversationasync';
  static readonly getApiChatCountunreadmessagesinconvoPath = '/api/chat/countunreadmessagesinconvo';
  static readonly getApiChatMarkmessagesasreadasyncPath = '/api/chat/markmessagesasreadasync';
  static readonly getApiChatGetconvosbysearchasyncPath = '/api/chat/getconvosbysearchasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ChatService.GetApiChatStartconversationasyncParams` containing the following parameters:
   *
   * - `senderProfileId`:
   *
   * - `receiverProfileId`:
   *
   * - `name`:
   *
   * - `fromPost`:
   *
   * - `api-version`: The requested API version
   *
   * @return Starting Conversation
   */
  getApiChatStartconversationasyncResponse(params: ChatService.GetApiChatStartconversationasyncParams): __Observable<__StrictHttpResponse<UserConversationEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.senderProfileId != null) __params = __params.set('senderProfileId', params.senderProfileId.toString());
    if (params.receiverProfileId != null) __params = __params.set('receiverProfileId', params.receiverProfileId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.fromPost != null) __params = __params.set('fromPost', params.fromPost.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/chat/startconversationasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserConversationEntity>;
      })
    );
  }
  /**
   * @param params The `ChatService.GetApiChatStartconversationasyncParams` containing the following parameters:
   *
   * - `senderProfileId`:
   *
   * - `receiverProfileId`:
   *
   * - `name`:
   *
   * - `fromPost`:
   *
   * - `api-version`: The requested API version
   *
   * @return Starting Conversation
   */
  getApiChatStartconversationasync(params: ChatService.GetApiChatStartconversationasyncParams): __Observable<UserConversationEntity> {
    return this.getApiChatStartconversationasyncResponse(params).pipe(
      __map(_r => _r.body as UserConversationEntity)
    );
  }

  /**
   * @param params The `ChatService.GetApiChatSendmessageasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `message`:
   *
   * - `conversationId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Sending Message
   */
  getApiChatSendmessageasyncResponse(params: ChatService.GetApiChatSendmessageasyncParams): __Observable<__StrictHttpResponse<MessageEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.conversationId != null) __params = __params.set('conversationId', params.conversationId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/chat/sendmessageasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageEntity>;
      })
    );
  }
  /**
   * @param params The `ChatService.GetApiChatSendmessageasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `message`:
   *
   * - `conversationId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Sending Message
   */
  getApiChatSendmessageasync(params: ChatService.GetApiChatSendmessageasyncParams): __Observable<MessageEntity> {
    return this.getApiChatSendmessageasyncResponse(params).pipe(
      __map(_r => _r.body as MessageEntity)
    );
  }

  /**
   * @param params The `ChatService.GetApiChatGetconversationsforuserasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Conversations for User
   */
  getApiChatGetconversationsforuserasyncResponse(params: ChatService.GetApiChatGetconversationsforuserasyncParams): __Observable<__StrictHttpResponse<UserConversationEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/chat/getconversationsforuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserConversationEntity>;
      })
    );
  }
  /**
   * @param params The `ChatService.GetApiChatGetconversationsforuserasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Conversations for User
   */
  getApiChatGetconversationsforuserasync(params: ChatService.GetApiChatGetconversationsforuserasyncParams): __Observable<UserConversationEntity> {
    return this.getApiChatGetconversationsforuserasyncResponse(params).pipe(
      __map(_r => _r.body as UserConversationEntity)
    );
  }

  /**
   * @param params The `ChatService.GetApiChatGetmessagesbyconversationasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `conversationId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Messages for Conversation
   */
  getApiChatGetmessagesbyconversationasyncResponse(params: ChatService.GetApiChatGetmessagesbyconversationasyncParams): __Observable<__StrictHttpResponse<MessageEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.conversationId != null) __params = __params.set('conversationId', params.conversationId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/chat/getmessagesbyconversationasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageEntity>;
      })
    );
  }
  /**
   * @param params The `ChatService.GetApiChatGetmessagesbyconversationasyncParams` containing the following parameters:
   *
   * - `profileId`:
   *
   * - `conversationId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Messages for Conversation
   */
  getApiChatGetmessagesbyconversationasync(params: ChatService.GetApiChatGetmessagesbyconversationasyncParams): __Observable<MessageEntity> {
    return this.getApiChatGetmessagesbyconversationasyncResponse(params).pipe(
      __map(_r => _r.body as MessageEntity)
    );
  }

  /**
   * @param params The `ChatService.GetApiChatCountunreadmessagesinconvoParams` containing the following parameters:
   *
   * - `convoId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Messages for Conversation
   */
  getApiChatCountunreadmessagesinconvoResponse(params: ChatService.GetApiChatCountunreadmessagesinconvoParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.convoId != null) __params = __params.set('convoId', params.convoId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/chat/countunreadmessagesinconvo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `ChatService.GetApiChatCountunreadmessagesinconvoParams` containing the following parameters:
   *
   * - `convoId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Messages for Conversation
   */
  getApiChatCountunreadmessagesinconvo(params: ChatService.GetApiChatCountunreadmessagesinconvoParams): __Observable<number> {
    return this.getApiChatCountunreadmessagesinconvoResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `ChatService.GetApiChatMarkmessagesasreadasyncParams` containing the following parameters:
   *
   * - `convoId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Messages for Conversation
   */
  getApiChatMarkmessagesasreadasyncResponse(params: ChatService.GetApiChatMarkmessagesasreadasyncParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.convoId != null) __params = __params.set('convoId', params.convoId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/chat/markmessagesasreadasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `ChatService.GetApiChatMarkmessagesasreadasyncParams` containing the following parameters:
   *
   * - `convoId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Messages for Conversation
   */
  getApiChatMarkmessagesasreadasync(params: ChatService.GetApiChatMarkmessagesasreadasyncParams): __Observable<number> {
    return this.getApiChatMarkmessagesasreadasyncResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `ChatService.GetApiChatGetconvosbysearchasyncParams` containing the following parameters:
   *
   * - `searchString`:
   *
   * - `profileId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Convos By Search string
   */
  getApiChatGetconvosbysearchasyncResponse(params: ChatService.GetApiChatGetconvosbysearchasyncParams): __Observable<__StrictHttpResponse<UserConversationEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/chat/getconvosbysearchasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserConversationEntity>;
      })
    );
  }
  /**
   * @param params The `ChatService.GetApiChatGetconvosbysearchasyncParams` containing the following parameters:
   *
   * - `searchString`:
   *
   * - `profileId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Convos By Search string
   */
  getApiChatGetconvosbysearchasync(params: ChatService.GetApiChatGetconvosbysearchasyncParams): __Observable<UserConversationEntity> {
    return this.getApiChatGetconvosbysearchasyncResponse(params).pipe(
      __map(_r => _r.body as UserConversationEntity)
    );
  }
}

module ChatService {

  /**
   * Parameters for getApiChatStartconversationasync
   */
  export interface GetApiChatStartconversationasyncParams {
    senderProfileId?: number;
    receiverProfileId?: number;
    name?: string;
    fromPost?: boolean;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiChatSendmessageasync
   */
  export interface GetApiChatSendmessageasyncParams {
    profileId?: number;
    message?: string;
    conversationId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiChatGetconversationsforuserasync
   */
  export interface GetApiChatGetconversationsforuserasyncParams {
    profileId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiChatGetmessagesbyconversationasync
   */
  export interface GetApiChatGetmessagesbyconversationasyncParams {
    profileId?: number;
    conversationId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiChatCountunreadmessagesinconvo
   */
  export interface GetApiChatCountunreadmessagesinconvoParams {
    convoId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiChatMarkmessagesasreadasync
   */
  export interface GetApiChatMarkmessagesasreadasyncParams {
    convoId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiChatGetconvosbysearchasync
   */
  export interface GetApiChatGetconvosbysearchasyncParams {
    searchString?: string;
    profileId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { ChatService }
