/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PaymentModelEntity } from '../models/payment-model-entity';
@Injectable({
  providedIn: 'root',
})
class PaymentModelService extends __BaseService {
  static readonly getApiPaymentmodelGetallpaymentmodelsasyncPath = '/api/paymentmodel/getallpaymentmodelsasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return Get All Payment Models
   */
  getApiPaymentmodelGetallpaymentmodelsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PaymentModelEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/paymentmodel/getallpaymentmodelsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentModelEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Payment Models
   */
  getApiPaymentmodelGetallpaymentmodelsasync(apiVersion?: string): __Observable<PaymentModelEntity> {
    return this.getApiPaymentmodelGetallpaymentmodelsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PaymentModelEntity)
    );
  }
}

module PaymentModelService {
}

export { PaymentModelService }
