/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AboutUsService } from './services/about-us.service';
import { BillingService } from './services/billing.service';
import { BoostService } from './services/boost.service';
import { CartService } from './services/cart.service';
import { CategoryImageService } from './services/category-image.service';
import { ChatService } from './services/chat.service';
import { CheckoutService } from './services/checkout.service';
import { ConfigurationService } from './services/configuration.service';
import { ContactSubmissionService } from './services/contact-submission.service';
import { DiscountCouponService } from './services/discount-coupon.service';
import { FaqService } from './services/faq.service';
import { FilterService } from './services/filter.service';
import { GeolocationService } from './services/geolocation.service';
import { HowItWorksService } from './services/how-it-works.service';
import { JobSchedulerService } from './services/job-scheduler.service';
import { LocationService } from './services/location.service';
import { NotificationService } from './services/notification.service';
import { PaymentModelService } from './services/payment-model.service';
import { PayPalCartService } from './services/pay-pal-cart.service';
import { PostService } from './services/post.service';
import { PostAbuseService } from './services/post-abuse.service';
import { PostImageService } from './services/post-image.service';
import { PostTypeService } from './services/post-type.service';
import { ReceiptService } from './services/receipt.service';
import { RecurrenceService } from './services/recurrence.service';
import { SiteImageService } from './services/site-image.service';
import { UserService } from './services/user.service';
import { VersionService } from './services/version.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AboutUsService,
    BillingService,
    BoostService,
    CartService,
    CategoryImageService,
    ChatService,
    CheckoutService,
    ConfigurationService,
    ContactSubmissionService,
    DiscountCouponService,
    FaqService,
    FilterService,
    GeolocationService,
    HowItWorksService,
    JobSchedulerService,
    LocationService,
    NotificationService,
    PaymentModelService,
    PayPalCartService,
    PostService,
    PostAbuseService,
    PostImageService,
    PostTypeService,
    ReceiptService,
    RecurrenceService,
    SiteImageService,
    UserService,
    VersionService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
