import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-layout',
  templateUrl: './splash-layout.component.html',
  styleUrls: ['./splash-layout.component.scss']
})
export class SplashLayoutComponent {
  title = 'app';
}
