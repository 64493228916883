/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostTypeImageEntity } from '../models/post-type-image-entity';
@Injectable({
  providedIn: 'root',
})
class CategoryImageService extends __BaseService {
  static readonly postApiCategoryimageUploadimageasyncPath = '/api/categoryimage/uploadimageasync';
  static readonly deleteApiCategoryimagePath = '/api/categoryimage';
  static readonly putApiCategoryimagePath = '/api/categoryimage';
  static readonly getApiCategoryimageGetpreviewimagebyidasyncPath = '/api/categoryimage/getpreviewimagebyidasync';
  static readonly getApiCategoryimageGetallposttypeimagesPath = '/api/categoryimage/getallposttypeimages';
  static readonly getApiCategoryimageGetsplashimageasyncPath = '/api/categoryimage/getsplashimageasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CategoryImageService.PostApiCategoryimageUploadimageasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add Images
   */
  postApiCategoryimageUploadimageasyncResponse(params: CategoryImageService.PostApiCategoryimageUploadimageasyncParams): __Observable<__StrictHttpResponse<PostTypeImageEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/categoryimage/uploadimageasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostTypeImageEntity>;
      })
    );
  }
  /**
   * @param params The `CategoryImageService.PostApiCategoryimageUploadimageasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add Images
   */
  postApiCategoryimageUploadimageasync(params: CategoryImageService.PostApiCategoryimageUploadimageasyncParams): __Observable<PostTypeImageEntity> {
    return this.postApiCategoryimageUploadimageasyncResponse(params).pipe(
      __map(_r => _r.body as PostTypeImageEntity)
    );
  }

  /**
   * @param params The `CategoryImageService.DeleteApiCategoryimageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiCategoryimageResponse(params: CategoryImageService.DeleteApiCategoryimageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/categoryimage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CategoryImageService.DeleteApiCategoryimageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiCategoryimage(params: CategoryImageService.DeleteApiCategoryimageParams): __Observable<null> {
    return this.deleteApiCategoryimageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CategoryImageService.PutApiCategoryimageParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiCategoryimageResponse(params: CategoryImageService.PutApiCategoryimageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/categoryimage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CategoryImageService.PutApiCategoryimageParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiCategoryimage(params: CategoryImageService.PutApiCategoryimageParams): __Observable<null> {
    return this.putApiCategoryimageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CategoryImageService.GetApiCategoryimageGetpreviewimagebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiCategoryimageGetpreviewimagebyidasyncResponse(params: CategoryImageService.GetApiCategoryimageGetpreviewimagebyidasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/categoryimage/getpreviewimagebyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CategoryImageService.GetApiCategoryimageGetpreviewimagebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiCategoryimageGetpreviewimagebyidasync(params: CategoryImageService.GetApiCategoryimageGetpreviewimagebyidasyncParams): __Observable<null> {
    return this.getApiCategoryimageGetpreviewimagebyidasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   */
  getApiCategoryimageGetallposttypeimagesResponse(apiVersion?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/categoryimage/getallposttypeimages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   */
  getApiCategoryimageGetallposttypeimages(apiVersion?: string): __Observable<null> {
    return this.getApiCategoryimageGetallposttypeimagesResponse(apiVersion).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CategoryImageService.GetApiCategoryimageGetsplashimageasyncParams` containing the following parameters:
   *
   * - `isCanadian`:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiCategoryimageGetsplashimageasyncResponse(params: CategoryImageService.GetApiCategoryimageGetsplashimageasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isCanadian != null) __params = __params.set('isCanadian', params.isCanadian.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/categoryimage/getsplashimageasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CategoryImageService.GetApiCategoryimageGetsplashimageasyncParams` containing the following parameters:
   *
   * - `isCanadian`:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiCategoryimageGetsplashimageasync(params: CategoryImageService.GetApiCategoryimageGetsplashimageasyncParams): __Observable<null> {
    return this.getApiCategoryimageGetsplashimageasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CategoryImageService {

  /**
   * Parameters for postApiCategoryimageUploadimageasync
   */
  export interface PostApiCategoryimageUploadimageasyncParams {
    body?: Array<string>;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiCategoryimage
   */
  export interface DeleteApiCategoryimageParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiCategoryimage
   */
  export interface PutApiCategoryimageParams {
    body?: PostTypeImageEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiCategoryimageGetpreviewimagebyidasync
   */
  export interface GetApiCategoryimageGetpreviewimagebyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiCategoryimageGetsplashimageasync
   */
  export interface GetApiCategoryimageGetsplashimageasyncParams {
    isCanadian?: boolean;
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { CategoryImageService }
