import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AccountComponent } from 'app/components/account/account.component';
import { LiveNotificationService } from 'app/services/live-notification.service';
import { SecurityService } from 'app/services/security.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})

export class MainLayoutComponent implements OnInit {

  title: string;
  isMobile: boolean;
  notificationBehaviourSubjectFromService: number;

  constructor(
    private securityService: SecurityService,
    public notificationService: LiveNotificationService,
    public platform: Platform,
    private cartRoute: Router,
  ) {
    this.title = 'Who Offers What';
    this.isMobile = false;
    this.platform.ready().then(() => {
      if (this.platform.is('ios') || this.platform.is('android')) {
        this.title = 'WOW';
        this.isMobile = true;
      }
    });
    
    this.notificationService.getNotificationBehaviourSubject().subscribe(value => {
      this.notificationBehaviourSubjectFromService = value;
  });

  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    if (this.isLoggedIn()) {
      this.notificationService.GetNotifications();
    }
  }

  public isLoggedIn(): Boolean {
    return this.securityService.isUserLoggedIn();
  }

  public isUserAdmin(): Boolean {
    return this.securityService.isUserAdmin();
  }

  public logout() {
    this.securityService.logout();
  }

  public navigateToCart() {
    this.cartRoute.navigate(['/account']).then((resp: any) => {
      const element: HTMLElement = document.getElementById('auto-trigger-cart') as HTMLElement;
      element.click();
    });
  }

}
