import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { AppInitService, ConfigDto } from '../ApplicationInit/app-init.service';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import { LocalStorageKeys } from 'app/constants';
import { takeUntil } from 'rxjs/operators';
import { SnackBarService } from 'app/services/snackbar.service';
import { ActionTypes } from 'app/api/models/action-types';

@Injectable()
export class ApiInterceptor implements HttpInterceptor, OnDestroy {

  private c: ConfigDto;
  private unsubscribe = new Subject();

  constructor(config: AppInitService, public platform: Platform, private router: Router, private snackbarService: SnackBarService) {
    config.loadConfigurations().pipe(takeUntil(this.unsubscribe)).subscribe(a => {
      this.c = a;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (req.url.indexOf('api') > -1) {
        if (this.platform.is('ios') || this.platform.is('android')) {
          req = req.clone({
            withCredentials: true,
            url: req.url.replace('API', this.c.apiExternal),
          });
        }
        else {
          req = req.clone({
            withCredentials: true,
            url: req.url.replace('API', this.c.api)
          });
        }
      }

      return next.handle(req).do(() => {}, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if((err.status === 401 || err.status === 403 || err.status === 0) && req.url.indexOf('refreshtokenasync') !== -1){
            localStorage.setItem(LocalStorageKeys.IsUserGuest, 'true');
            localStorage.setItem(LocalStorageKeys.IsUserLoggedIn, "false");
            localStorage.setItem(LocalStorageKeys.IsUserAdmin, "false");
            localStorage.setItem(LocalStorageKeys.IsUserSuperAdmin, "false");
            localStorage.removeItem(LocalStorageKeys.userGuid);
            localStorage.removeItem(LocalStorageKeys.JwtExpiryKey);
            localStorage.removeItem(LocalStorageKeys.RefreshExpiryKey);
            this.router.navigate(['/login-register']);            
          }
          else if (err.status === 401){
            this.router.navigate(['/login-register']);            
          }
          if(err.status === 400 && req.url.indexOf('loginuser') !== -1){
            this.snackbarService.displayErrorSnackbar("Account credentials incorrect! Please try again.");
          }
          // Todo: Implement this page and add it to the route paths.
          else if(err.status === 403){
            this.router.navigate(['/unauthorized']);
          }
          // Todo: Implement this page and add it to the route paths.
          else if(err.status === 404){
            this.router.navigate(['/notfound']);
          }else if (err.status === 666){

          }
          // Todo: Implement this page and add it to the route paths.
          else {
            this.router.navigate(['/error']);
          }
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
