/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AbuseReportEntity } from '../models/abuse-report-entity';
import { ReportReasonEntity } from '../models/report-reason-entity';
@Injectable({
  providedIn: 'root',
})
class PostAbuseService extends __BaseService {
  static readonly getApiPostabuseGetallpostabusereportsasyncPath = '/api/postabuse/getallpostabusereportsasync';
  static readonly getApiPostabuseGetallreasonsasyncPath = '/api/postabuse/getallreasonsasync';
  static readonly postApiPostabuseAddnewreasonasyncPath = '/api/postabuse/addnewreasonasync';
  static readonly postApiPostabuseAddnewreportasyncPath = '/api/postabuse/addnewreportasync';
  static readonly getApiPostabuseGetabusereportsbypostidPath = '/api/postabuse/getabusereportsbypostid';
  static readonly getApiPostabuseHasusersubmittedabusereportasyncPath = '/api/postabuse/hasusersubmittedabusereportasync';
  static readonly putApiPostabuseUpdatereasonasyncPath = '/api/postabuse/updatereasonasync';
  static readonly deleteApiPostabuseDeletereasonasyncPath = '/api/postabuse/deletereasonasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return Get All Abuse Reports
   */
  getApiPostabuseGetallpostabusereportsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<AbuseReportEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/postabuse/getallpostabusereportsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AbuseReportEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Abuse Reports
   */
  getApiPostabuseGetallpostabusereportsasync(apiVersion?: string): __Observable<AbuseReportEntity> {
    return this.getApiPostabuseGetallpostabusereportsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as AbuseReportEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Abuse Reasons
   */
  getApiPostabuseGetallreasonsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<ReportReasonEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/postabuse/getallreasonsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportReasonEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Abuse Reasons
   */
  getApiPostabuseGetallreasonsasync(apiVersion?: string): __Observable<ReportReasonEntity> {
    return this.getApiPostabuseGetallreasonsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as ReportReasonEntity)
    );
  }

  /**
   * @param params The `PostAbuseService.PostApiPostabuseAddnewreasonasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New Abuse Reason
   */
  postApiPostabuseAddnewreasonasyncResponse(params: PostAbuseService.PostApiPostabuseAddnewreasonasyncParams): __Observable<__StrictHttpResponse<ReportReasonEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/postabuse/addnewreasonasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportReasonEntity>;
      })
    );
  }
  /**
   * @param params The `PostAbuseService.PostApiPostabuseAddnewreasonasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New Abuse Reason
   */
  postApiPostabuseAddnewreasonasync(params: PostAbuseService.PostApiPostabuseAddnewreasonasyncParams): __Observable<ReportReasonEntity> {
    return this.postApiPostabuseAddnewreasonasyncResponse(params).pipe(
      __map(_r => _r.body as ReportReasonEntity)
    );
  }

  /**
   * @param params The `PostAbuseService.PostApiPostabuseAddnewreportasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New Abuse Report
   */
  postApiPostabuseAddnewreportasyncResponse(params: PostAbuseService.PostApiPostabuseAddnewreportasyncParams): __Observable<__StrictHttpResponse<AbuseReportEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/postabuse/addnewreportasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AbuseReportEntity>;
      })
    );
  }
  /**
   * @param params The `PostAbuseService.PostApiPostabuseAddnewreportasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New Abuse Report
   */
  postApiPostabuseAddnewreportasync(params: PostAbuseService.PostApiPostabuseAddnewreportasyncParams): __Observable<AbuseReportEntity> {
    return this.postApiPostabuseAddnewreportasyncResponse(params).pipe(
      __map(_r => _r.body as AbuseReportEntity)
    );
  }

  /**
   * @param params The `PostAbuseService.GetApiPostabuseGetabusereportsbypostidParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Abuse Reports By Post Id
   */
  getApiPostabuseGetabusereportsbypostidResponse(params: PostAbuseService.GetApiPostabuseGetabusereportsbypostidParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/postabuse/getabusereportsbypostid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `PostAbuseService.GetApiPostabuseGetabusereportsbypostidParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Abuse Reports By Post Id
   */
  getApiPostabuseGetabusereportsbypostid(params: PostAbuseService.GetApiPostabuseGetabusereportsbypostidParams): __Observable<number> {
    return this.getApiPostabuseGetabusereportsbypostidResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `PostAbuseService.GetApiPostabuseHasusersubmittedabusereportasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `guid`:
   *
   * - `api-version`: The requested API version
   *
   * @return Has User Submitted Abuse Report
   */
  getApiPostabuseHasusersubmittedabusereportasyncResponse(params: PostAbuseService.GetApiPostabuseHasusersubmittedabusereportasyncParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.guid != null) __params = __params.set('guid', params.guid.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/postabuse/hasusersubmittedabusereportasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `PostAbuseService.GetApiPostabuseHasusersubmittedabusereportasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `guid`:
   *
   * - `api-version`: The requested API version
   *
   * @return Has User Submitted Abuse Report
   */
  getApiPostabuseHasusersubmittedabusereportasync(params: PostAbuseService.GetApiPostabuseHasusersubmittedabusereportasyncParams): __Observable<boolean> {
    return this.getApiPostabuseHasusersubmittedabusereportasyncResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `PostAbuseService.PutApiPostabuseUpdatereasonasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiPostabuseUpdatereasonasyncResponse(params: PostAbuseService.PutApiPostabuseUpdatereasonasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/postabuse/updatereasonasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostAbuseService.PutApiPostabuseUpdatereasonasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiPostabuseUpdatereasonasync(params: PostAbuseService.PutApiPostabuseUpdatereasonasyncParams): __Observable<null> {
    return this.putApiPostabuseUpdatereasonasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostAbuseService.DeleteApiPostabuseDeletereasonasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiPostabuseDeletereasonasyncResponse(params: PostAbuseService.DeleteApiPostabuseDeletereasonasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/postabuse/deletereasonasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostAbuseService.DeleteApiPostabuseDeletereasonasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiPostabuseDeletereasonasync(params: PostAbuseService.DeleteApiPostabuseDeletereasonasyncParams): __Observable<null> {
    return this.deleteApiPostabuseDeletereasonasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PostAbuseService {

  /**
   * Parameters for postApiPostabuseAddnewreasonasync
   */
  export interface PostApiPostabuseAddnewreasonasyncParams {
    body?: ReportReasonEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostabuseAddnewreportasync
   */
  export interface PostApiPostabuseAddnewreportasyncParams {
    body?: AbuseReportEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostabuseGetabusereportsbypostid
   */
  export interface GetApiPostabuseGetabusereportsbypostidParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostabuseHasusersubmittedabusereportasync
   */
  export interface GetApiPostabuseHasusersubmittedabusereportasyncParams {
    postId?: number;
    guid?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiPostabuseUpdatereasonasync
   */
  export interface PutApiPostabuseUpdatereasonasyncParams {
    body?: ReportReasonEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiPostabuseDeletereasonasync
   */
  export interface DeleteApiPostabuseDeletereasonasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { PostAbuseService }
