/* tslint:disable */
type ActionTypes =
  'Success' |
  'Info' |
  'Warning' |
  'Error' |
  'Redirect';
module ActionTypes {
  export const SUCCESS: ActionTypes = 'Success';
  export const INFO: ActionTypes = 'Info';
  export const WARNING: ActionTypes = 'Warning';
  export const ERROR: ActionTypes = 'Error';
  export const REDIRECT: ActionTypes = 'Redirect';
  export function values(): ActionTypes[] {
    return [
      SUCCESS,
      INFO,
      WARNING,
      ERROR,
      REDIRECT
    ];
  }
}

export { ActionTypes }