import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ActionTypes, TokenData } from 'app/api/models';
import { UserService } from 'app/api/services';
import { LocalStorageKeys } from 'app/constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LiveMessengerService } from './live-messenger.service';
import { LiveNotificationService } from './live-notification.service';
import { SnackBarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService implements OnDestroy {

  private unsubscribe = new Subject();

  constructor(
    private userService: UserService,
    private _snackBarService: SnackBarService,
    private router: Router,
    private LiveNotificationService: LiveNotificationService,
    private liveMessageService: LiveMessengerService) { }

  public isUserLoggedIn(): Boolean {
    var localItem = localStorage.getItem(LocalStorageKeys.IsUserLoggedIn);
    if (localItem === undefined || localItem === null) {
      return false;
    }
    else {
      return localItem === 'true';
    }
  }

  public getUserGuid(): string {
    return localStorage.getItem(LocalStorageKeys.userGuid);
  }

  public doesUserGuidMatch(guid: string): Boolean {
    var userGuid = localStorage.getItem(LocalStorageKeys.userGuid);
    if (userGuid === undefined || userGuid === null) {
      return false;
    }
    else {
      return userGuid === guid;
    }
  }

  public isUserGuest(): Boolean {
    var storageItem = localStorage.getItem(LocalStorageKeys.IsUserGuest);
    if (storageItem === undefined || storageItem === null) {
      return false;
    }
    else {
      return storageItem === 'true';
    }
  }

  public isUserAdmin(): Boolean {
    return localStorage.getItem(LocalStorageKeys.IsUserAdmin) == "true" || localStorage.getItem(LocalStorageKeys.IsUserAdmin) == "True";
  }

  public isUserSuperAdmin(): Boolean {
    return localStorage.getItem(LocalStorageKeys.IsUserSuperAdmin) == "true" || localStorage.getItem(LocalStorageKeys.IsUserSuperAdmin) == "True";
  }

  public login(data: TokenData) {
    localStorage.setItem(LocalStorageKeys.JwtExpiryKey, data.jwtTokenExpiry);
    localStorage.setItem(LocalStorageKeys.IsUserGuest, 'false');
    localStorage.setItem(LocalStorageKeys.RefreshExpiryKey, data.refreshTokenExpiry);
    localStorage.setItem(LocalStorageKeys.IsUserLoggedIn, "true");
    localStorage.setItem(LocalStorageKeys.IsUserAdmin, data.isAdmin.toString());
    localStorage.setItem(LocalStorageKeys.IsUserSuperAdmin, data.isSuperAdmin.toString());
    localStorage.setItem(LocalStorageKeys.userGuid, data.userGuid);
    this.router.navigate(['/main-page']);
    this._snackBarService.displaySnackbar('You have been logged in!');
    this.LiveNotificationService.startConnection();
  }

  public logout() {
    this.userService.postApiUserLoguseroutasync().subscribe((data: any) => {
      this.liveMessageService.ClearData();
      localStorage.setItem(LocalStorageKeys.IsUserGuest, 'true');
      localStorage.setItem(LocalStorageKeys.IsUserLoggedIn, "false");
      localStorage.setItem(LocalStorageKeys.IsUserAdmin, "false");
      localStorage.setItem(LocalStorageKeys.IsUserSuperAdmin, "false");
      localStorage.removeItem(LocalStorageKeys.userGuid);
      localStorage.removeItem(LocalStorageKeys.JwtExpiryKey);
      localStorage.removeItem(LocalStorageKeys.RefreshExpiryKey);
      this.LiveNotificationService.stopSignalRConnection();
      this.router.navigate(['/login-register']);
      this._snackBarService.displaySnackbar('You have been logged out!');
    },
      err => {
        //PROBLEM LOGGING OUT
        this.router.navigate(['/login-register']);
        this._snackBarService.displaySnackbar(`Error while logging out: ${err}`, ActionTypes.ERROR);
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
