/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PostEntity } from '../models/post-entity';
import { SelectionEntity } from '../models/selection-entity';
import { SearchEntity } from '../models/search-entity';
import { UserPostRatingDto } from '../models/user-post-rating-dto';
import { RatingEntity } from '../models/rating-entity';
import { FavoriteEntity } from '../models/favorite-entity';
import { CommentEntity } from '../models/comment-entity';
import { ProfanityDto } from '../models/profanity-dto';
@Injectable({
  providedIn: 'root',
})
class PostService extends __BaseService {
  static readonly postApiPostAddlowclicknotificationasyncPath = '/api/post/addlowclicknotificationasync';
  static readonly getApiPostGetallpostsasyncPath = '/api/post/getallpostsasync';
  static readonly getApiPostGetallboostedpostsasyncPath = '/api/post/getallboostedpostsasync';
  static readonly getApiPostGetallpoststatusesasyncPath = '/api/post/getallpoststatusesasync';
  static readonly postApiPostGetpostsbysearchasyncPath = '/api/post/getpostsbysearchasync';
  static readonly getApiPostGetpostsbytypeasyncPath = '/api/post/getpostsbytypeasync';
  static readonly getApiPostGetboostedpostsbytypeasyncPath = '/api/post/getboostedpostsbytypeasync';
  static readonly getApiPostGetpostbyidasyncPath = '/api/post/getpostbyidasync';
  static readonly getApiPostGetpostbysearchqueryasyncPath = '/api/post/getpostbysearchqueryasync';
  static readonly getApiPostGetaveragepostratingasyncPath = '/api/post/getaveragepostratingasync';
  static readonly postApiPostCheckifuserpostratedasyncPath = '/api/post/checkifuserpostratedasync';
  static readonly postApiPostAddpostratingasyncPath = '/api/post/addpostratingasync';
  static readonly getApiPostAddpostshareasyncPath = '/api/post/addpostshareasync';
  static readonly putApiPostTogglenotificationsforfavoriteasyncPath = '/api/post/togglenotificationsforfavoriteasync';
  static readonly getApiPostGetownedpostsbyuserasyncPath = '/api/post/getownedpostsbyuserasync';
  static readonly getApiPostGetfavoritepostsbyuserasyncPath = '/api/post/getfavoritepostsbyuserasync';
  static readonly postApiPostAddpostasyncPath = '/api/post/addpostasync';
  static readonly postApiPostUpdatepostasyncPath = '/api/post/updatepostasync';
  static readonly postApiPostDeletepostasyncPath = '/api/post/deletepostasync';
  static readonly getApiPostGetallunverifiedpostsasyncPath = '/api/post/getallunverifiedpostsasync';
  static readonly getApiPostVerifypostasyncPath = '/api/post/verifypostasync';
  static readonly getApiPostDeactivatepostasyncPath = '/api/post/deactivatepostasync';
  static readonly postApiPostFavoriteorunfavoritepostasyncPath = '/api/post/favoriteorunfavoritepostasync';
  static readonly postApiPostCheckifuserfavoritedpostasyncPath = '/api/post/checkifuserfavoritedpostasync';
  static readonly postApiPostAddcommentasyncPath = '/api/post/addcommentasync';
  static readonly postApiPostTestprofanityPath = '/api/post/testprofanity';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PostService.PostApiPostAddlowclicknotificationasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostAddlowclicknotificationasyncResponse(params: PostService.PostApiPostAddlowclicknotificationasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/addlowclicknotificationasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostAddlowclicknotificationasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostAddlowclicknotificationasync(params: PostService.PostApiPostAddlowclicknotificationasyncParams): __Observable<null> {
    return this.postApiPostAddlowclicknotificationasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Posts
   */
  getApiPostGetallpostsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getallpostsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Posts
   */
  getApiPostGetallpostsasync(apiVersion?: string): __Observable<PostEntity> {
    return this.getApiPostGetallpostsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Posts
   */
  getApiPostGetallboostedpostsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getallboostedpostsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Posts
   */
  getApiPostGetallboostedpostsasync(apiVersion?: string): __Observable<PostEntity> {
    return this.getApiPostGetallboostedpostsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get All Post Statuses
   */
  getApiPostGetallpoststatusesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<SelectionEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getallpoststatusesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SelectionEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get All Post Statuses
   */
  getApiPostGetallpoststatusesasync(apiVersion?: string): __Observable<SelectionEntity> {
    return this.getApiPostGetallpoststatusesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as SelectionEntity)
    );
  }

  /**
   * @param params The `PostService.PostApiPostGetpostsbysearchasyncParams` containing the following parameters:
   *
   * - `showDetails`:
   *
   * - `isLatestInArea`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Posts By Search
   */
  postApiPostGetpostsbysearchasyncResponse(params: PostService.PostApiPostGetpostsbysearchasyncParams): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showDetails != null) __params = __params.set('showDetails', params.showDetails.toString());
    if (params.isLatestInArea != null) __params = __params.set('isLatestInArea', params.isLatestInArea.toString());
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/getpostsbysearchasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostGetpostsbysearchasyncParams` containing the following parameters:
   *
   * - `showDetails`:
   *
   * - `isLatestInArea`:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Posts By Search
   */
  postApiPostGetpostsbysearchasync(params: PostService.PostApiPostGetpostsbysearchasyncParams): __Observable<PostEntity> {
    return this.postApiPostGetpostsbysearchasyncResponse(params).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param params The `PostService.GetApiPostGetpostsbytypeasyncParams` containing the following parameters:
   *
   * - `showDetails`:
   *
   * - `postTypeId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Type
   */
  getApiPostGetpostsbytypeasyncResponse(params: PostService.GetApiPostGetpostsbytypeasyncParams): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showDetails != null) __params = __params.set('showDetails', params.showDetails.toString());
    if (params.postTypeId != null) __params = __params.set('postTypeId', params.postTypeId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getpostsbytypeasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostGetpostsbytypeasyncParams` containing the following parameters:
   *
   * - `showDetails`:
   *
   * - `postTypeId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Type
   */
  getApiPostGetpostsbytypeasync(params: PostService.GetApiPostGetpostsbytypeasyncParams): __Observable<PostEntity> {
    return this.getApiPostGetpostsbytypeasyncResponse(params).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param params The `PostService.GetApiPostGetboostedpostsbytypeasyncParams` containing the following parameters:
   *
   * - `showDetails`:
   *
   * - `postTypeId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Type
   */
  getApiPostGetboostedpostsbytypeasyncResponse(params: PostService.GetApiPostGetboostedpostsbytypeasyncParams): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showDetails != null) __params = __params.set('showDetails', params.showDetails.toString());
    if (params.postTypeId != null) __params = __params.set('postTypeId', params.postTypeId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getboostedpostsbytypeasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostGetboostedpostsbytypeasyncParams` containing the following parameters:
   *
   * - `showDetails`:
   *
   * - `postTypeId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Type
   */
  getApiPostGetboostedpostsbytypeasync(params: PostService.GetApiPostGetboostedpostsbytypeasyncParams): __Observable<PostEntity> {
    return this.getApiPostGetboostedpostsbytypeasyncResponse(params).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param params The `PostService.GetApiPostGetpostbyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Id
   */
  getApiPostGetpostbyidasyncResponse(params: PostService.GetApiPostGetpostbyidasyncParams): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getpostbyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostGetpostbyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Id
   */
  getApiPostGetpostbyidasync(params: PostService.GetApiPostGetpostbyidasyncParams): __Observable<PostEntity> {
    return this.getApiPostGetpostbyidasyncResponse(params).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param params The `PostService.GetApiPostGetpostbysearchqueryasyncParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Search Query
   */
  getApiPostGetpostbysearchqueryasyncResponse(params: PostService.GetApiPostGetpostbysearchqueryasyncParams): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getpostbysearchqueryasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostGetpostbysearchqueryasyncParams` containing the following parameters:
   *
   * - `search`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Post By Search Query
   */
  getApiPostGetpostbysearchqueryasync(params: PostService.GetApiPostGetpostbysearchqueryasyncParams): __Observable<PostEntity> {
    return this.getApiPostGetpostbysearchqueryasyncResponse(params).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param params The `PostService.GetApiPostGetaveragepostratingasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Average Post Rating
   */
  getApiPostGetaveragepostratingasyncResponse(params: PostService.GetApiPostGetaveragepostratingasyncParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getaveragepostratingasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostGetaveragepostratingasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Average Post Rating
   */
  getApiPostGetaveragepostratingasync(params: PostService.GetApiPostGetaveragepostratingasyncParams): __Observable<number> {
    return this.getApiPostGetaveragepostratingasyncResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `PostService.PostApiPostCheckifuserpostratedasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Check if user has rated post.
   */
  postApiPostCheckifuserpostratedasyncResponse(params: PostService.PostApiPostCheckifuserpostratedasyncParams): __Observable<__StrictHttpResponse<UserPostRatingDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/checkifuserpostratedasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPostRatingDto>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostCheckifuserpostratedasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Check if user has rated post.
   */
  postApiPostCheckifuserpostratedasync(params: PostService.PostApiPostCheckifuserpostratedasyncParams): __Observable<UserPostRatingDto> {
    return this.postApiPostCheckifuserpostratedasyncResponse(params).pipe(
      __map(_r => _r.body as UserPostRatingDto)
    );
  }

  /**
   * @param params The `PostService.PostApiPostAddpostratingasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostAddpostratingasyncResponse(params: PostService.PostApiPostAddpostratingasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/addpostratingasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostAddpostratingasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostAddpostratingasync(params: PostService.PostApiPostAddpostratingasyncParams): __Observable<null> {
    return this.postApiPostAddpostratingasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.GetApiPostAddpostshareasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostAddpostshareasyncResponse(params: PostService.GetApiPostAddpostshareasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/addpostshareasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostAddpostshareasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostAddpostshareasync(params: PostService.GetApiPostAddpostshareasyncParams): __Observable<null> {
    return this.getApiPostAddpostshareasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.PutApiPostTogglenotificationsforfavoriteasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  putApiPostTogglenotificationsforfavoriteasyncResponse(params: PostService.PutApiPostTogglenotificationsforfavoriteasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/post/togglenotificationsforfavoriteasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.PutApiPostTogglenotificationsforfavoriteasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  putApiPostTogglenotificationsforfavoriteasync(params: PostService.PutApiPostTogglenotificationsforfavoriteasyncParams): __Observable<null> {
    return this.putApiPostTogglenotificationsforfavoriteasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   */
  getApiPostGetownedpostsbyuserasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getownedpostsbyuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   */
  getApiPostGetownedpostsbyuserasync(apiVersion?: string): __Observable<null> {
    return this.getApiPostGetownedpostsbyuserasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   */
  getApiPostGetfavoritepostsbyuserasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getfavoritepostsbyuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   */
  getApiPostGetfavoritepostsbyuserasync(apiVersion?: string): __Observable<null> {
    return this.getApiPostGetfavoritepostsbyuserasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.PostApiPostAddpostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Adding New Post
   */
  postApiPostAddpostasyncResponse(params: PostService.PostApiPostAddpostasyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/addpostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostAddpostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Adding New Post
   */
  postApiPostAddpostasync(params: PostService.PostApiPostAddpostasyncParams): __Observable<string> {
    return this.postApiPostAddpostasyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `PostService.PostApiPostUpdatepostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostUpdatepostasyncResponse(params: PostService.PostApiPostUpdatepostasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/updatepostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostUpdatepostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostUpdatepostasync(params: PostService.PostApiPostUpdatepostasyncParams): __Observable<null> {
    return this.postApiPostUpdatepostasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.PostApiPostDeletepostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostDeletepostasyncResponse(params: PostService.PostApiPostDeletepostasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/deletepostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostDeletepostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostDeletepostasync(params: PostService.PostApiPostDeletepostasyncParams): __Observable<null> {
    return this.postApiPostDeletepostasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Adding New Post
   */
  getApiPostGetallunverifiedpostsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<PostEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/getallunverifiedpostsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PostEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Adding New Post
   */
  getApiPostGetallunverifiedpostsasync(apiVersion?: string): __Observable<PostEntity> {
    return this.getApiPostGetallunverifiedpostsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as PostEntity)
    );
  }

  /**
   * @param params The `PostService.GetApiPostVerifypostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostVerifypostasyncResponse(params: PostService.GetApiPostVerifypostasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/verifypostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostVerifypostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostVerifypostasync(params: PostService.GetApiPostVerifypostasyncParams): __Observable<null> {
    return this.getApiPostVerifypostasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.GetApiPostDeactivatepostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostDeactivatepostasyncResponse(params: PostService.GetApiPostDeactivatepostasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/post/deactivatepostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.GetApiPostDeactivatepostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostDeactivatepostasync(params: PostService.GetApiPostDeactivatepostasyncParams): __Observable<null> {
    return this.getApiPostDeactivatepostasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.PostApiPostFavoriteorunfavoritepostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostFavoriteorunfavoritepostasyncResponse(params: PostService.PostApiPostFavoriteorunfavoritepostasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/favoriteorunfavoritepostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostFavoriteorunfavoritepostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostFavoriteorunfavoritepostasync(params: PostService.PostApiPostFavoriteorunfavoritepostasyncParams): __Observable<null> {
    return this.postApiPostFavoriteorunfavoritepostasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.PostApiPostCheckifuserfavoritedpostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Checking If User Favorited Post
   */
  postApiPostCheckifuserfavoritedpostasyncResponse(params: PostService.PostApiPostCheckifuserfavoritedpostasyncParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/checkifuserfavoritedpostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostCheckifuserfavoritedpostasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Checking If User Favorited Post
   */
  postApiPostCheckifuserfavoritedpostasync(params: PostService.PostApiPostCheckifuserfavoritedpostasyncParams): __Observable<boolean> {
    return this.postApiPostCheckifuserfavoritedpostasyncResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `PostService.PostApiPostAddcommentasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostAddcommentasyncResponse(params: PostService.PostApiPostAddcommentasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/addcommentasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostAddcommentasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  postApiPostAddcommentasync(params: PostService.PostApiPostAddcommentasyncParams): __Observable<null> {
    return this.postApiPostAddcommentasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostService.PostApiPostTestprofanityParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Checking If Profane
   */
  postApiPostTestprofanityResponse(params: PostService.PostApiPostTestprofanityParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/post/testprofanity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `PostService.PostApiPostTestprofanityParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Checking If Profane
   */
  postApiPostTestprofanity(params: PostService.PostApiPostTestprofanityParams): __Observable<boolean> {
    return this.postApiPostTestprofanityResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module PostService {

  /**
   * Parameters for postApiPostAddlowclicknotificationasync
   */
  export interface PostApiPostAddlowclicknotificationasyncParams {
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostGetpostsbysearchasync
   */
  export interface PostApiPostGetpostsbysearchasyncParams {
    showDetails?: boolean;
    isLatestInArea?: boolean;
    body?: SearchEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostGetpostsbytypeasync
   */
  export interface GetApiPostGetpostsbytypeasyncParams {
    showDetails?: boolean;
    postTypeId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostGetboostedpostsbytypeasync
   */
  export interface GetApiPostGetboostedpostsbytypeasyncParams {
    showDetails?: boolean;
    postTypeId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostGetpostbyidasync
   */
  export interface GetApiPostGetpostbyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostGetpostbysearchqueryasync
   */
  export interface GetApiPostGetpostbysearchqueryasyncParams {
    search?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostGetaveragepostratingasync
   */
  export interface GetApiPostGetaveragepostratingasyncParams {
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostCheckifuserpostratedasync
   */
  export interface PostApiPostCheckifuserpostratedasyncParams {
    body?: RatingEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostAddpostratingasync
   */
  export interface PostApiPostAddpostratingasyncParams {
    body?: RatingEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostAddpostshareasync
   */
  export interface GetApiPostAddpostshareasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiPostTogglenotificationsforfavoriteasync
   */
  export interface PutApiPostTogglenotificationsforfavoriteasyncParams {
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostAddpostasync
   */
  export interface PostApiPostAddpostasyncParams {
    body?: PostEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostUpdatepostasync
   */
  export interface PostApiPostUpdatepostasyncParams {
    body?: PostEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostDeletepostasync
   */
  export interface PostApiPostDeletepostasyncParams {
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostVerifypostasync
   */
  export interface GetApiPostVerifypostasyncParams {
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostDeactivatepostasync
   */
  export interface GetApiPostDeactivatepostasyncParams {
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostFavoriteorunfavoritepostasync
   */
  export interface PostApiPostFavoriteorunfavoritepostasyncParams {
    body?: FavoriteEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostCheckifuserfavoritedpostasync
   */
  export interface PostApiPostCheckifuserfavoritedpostasyncParams {
    body?: FavoriteEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostAddcommentasync
   */
  export interface PostApiPostAddcommentasyncParams {
    body?: CommentEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiPostTestprofanity
   */
  export interface PostApiPostTestprofanityParams {
    body?: ProfanityDto;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { PostService }
