/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RecurrenceEntity } from '../models/recurrence-entity';
import { WeekdayEntity } from '../models/weekday-entity';
import { RecurrenceWeekdayWrapper } from '../models/recurrence-weekday-wrapper';
@Injectable({
  providedIn: 'root',
})
class RecurrenceService extends __BaseService {
  static readonly getApiRecurrenceGetrecurrencebypostasyncPath = '/api/recurrence/getrecurrencebypostasync';
  static readonly getApiRecurrenceGetdaysofweekasyncPath = '/api/recurrence/getdaysofweekasync';
  static readonly postApiRecurrenceAddrecurrenceasyncPath = '/api/recurrence/addrecurrenceasync';
  static readonly putApiRecurrenceUpdaterecurrenceasyncPath = '/api/recurrence/updaterecurrenceasync';
  static readonly deleteApiRecurrenceDeleterecurrenceasyncPath = '/api/recurrence/deleterecurrenceasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RecurrenceService.GetApiRecurrenceGetrecurrencebypostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Recurrence For Post
   */
  getApiRecurrenceGetrecurrencebypostasyncResponse(params: RecurrenceService.GetApiRecurrenceGetrecurrencebypostasyncParams): __Observable<__StrictHttpResponse<RecurrenceEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/recurrence/getrecurrencebypostasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecurrenceEntity>;
      })
    );
  }
  /**
   * @param params The `RecurrenceService.GetApiRecurrenceGetrecurrencebypostasyncParams` containing the following parameters:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Get Recurrence For Post
   */
  getApiRecurrenceGetrecurrencebypostasync(params: RecurrenceService.GetApiRecurrenceGetrecurrencebypostasyncParams): __Observable<RecurrenceEntity> {
    return this.getApiRecurrenceGetrecurrencebypostasyncResponse(params).pipe(
      __map(_r => _r.body as RecurrenceEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Get Days of the Week
   */
  getApiRecurrenceGetdaysofweekasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<WeekdayEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/recurrence/getdaysofweekasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WeekdayEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Get Days of the Week
   */
  getApiRecurrenceGetdaysofweekasync(apiVersion?: string): __Observable<WeekdayEntity> {
    return this.getApiRecurrenceGetdaysofweekasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as WeekdayEntity)
    );
  }

  /**
   * @param params The `RecurrenceService.PostApiRecurrenceAddrecurrenceasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Adding New Recurrence
   */
  postApiRecurrenceAddrecurrenceasyncResponse(params: RecurrenceService.PostApiRecurrenceAddrecurrenceasyncParams): __Observable<__StrictHttpResponse<RecurrenceWeekdayWrapper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/recurrence/addrecurrenceasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecurrenceWeekdayWrapper>;
      })
    );
  }
  /**
   * @param params The `RecurrenceService.PostApiRecurrenceAddrecurrenceasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Adding New Recurrence
   */
  postApiRecurrenceAddrecurrenceasync(params: RecurrenceService.PostApiRecurrenceAddrecurrenceasyncParams): __Observable<RecurrenceWeekdayWrapper> {
    return this.postApiRecurrenceAddrecurrenceasyncResponse(params).pipe(
      __map(_r => _r.body as RecurrenceWeekdayWrapper)
    );
  }

  /**
   * @param params The `RecurrenceService.PutApiRecurrenceUpdaterecurrenceasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiRecurrenceUpdaterecurrenceasyncResponse(params: RecurrenceService.PutApiRecurrenceUpdaterecurrenceasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/recurrence/updaterecurrenceasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RecurrenceService.PutApiRecurrenceUpdaterecurrenceasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiRecurrenceUpdaterecurrenceasync(params: RecurrenceService.PutApiRecurrenceUpdaterecurrenceasyncParams): __Observable<null> {
    return this.putApiRecurrenceUpdaterecurrenceasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `RecurrenceService.DeleteApiRecurrenceDeleterecurrenceasyncParams` containing the following parameters:
   *
   * - `recurrenceId`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiRecurrenceDeleterecurrenceasyncResponse(params: RecurrenceService.DeleteApiRecurrenceDeleterecurrenceasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.recurrenceId != null) __params = __params.set('recurrenceId', params.recurrenceId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/recurrence/deleterecurrenceasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RecurrenceService.DeleteApiRecurrenceDeleterecurrenceasyncParams` containing the following parameters:
   *
   * - `recurrenceId`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiRecurrenceDeleterecurrenceasync(params: RecurrenceService.DeleteApiRecurrenceDeleterecurrenceasyncParams): __Observable<null> {
    return this.deleteApiRecurrenceDeleterecurrenceasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RecurrenceService {

  /**
   * Parameters for getApiRecurrenceGetrecurrencebypostasync
   */
  export interface GetApiRecurrenceGetrecurrencebypostasyncParams {
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiRecurrenceAddrecurrenceasync
   */
  export interface PostApiRecurrenceAddrecurrenceasyncParams {
    body?: RecurrenceWeekdayWrapper;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiRecurrenceUpdaterecurrenceasync
   */
  export interface PutApiRecurrenceUpdaterecurrenceasyncParams {
    body?: RecurrenceWeekdayWrapper;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiRecurrenceDeleterecurrenceasync
   */
  export interface DeleteApiRecurrenceDeleterecurrenceasyncParams {
    recurrenceId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { RecurrenceService }
