import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
declare var window: any;

export interface ConfigDto {
  api: string;
  apiExternal: string;
  signalrUrl: string;
  signalrUrlExternal: string;
}

@Injectable({providedIn: 'root'})
export class AppInitService {
  private readonly CONFIG_URL = '../../assets/config/app.config.json';
  private configuration$: Observable<ConfigDto>;
  // This is the method you want to call at bootstrap
  constructor(private http: HttpClient) {
  }

  public loadConfigurations(): Observable<ConfigDto> {
    if (!this.configuration$) {
      this.configuration$ = this.http.get<ConfigDto>(this.CONFIG_URL).pipe(
        shareReplay(1)
      );
    }
    return this.configuration$;
  }


}
