
  <ion-grid>
    <div>
      <ion-row class="light-background">
        <ion-col size="12">
          <h1 class="main-page-headers">Error</h1>
        </ion-col>
      </ion-row>
      
      <ion-row>
        <ion-col style="height:70vh;background-image: url(/assets/images/500.jpg);background-size:100% 90%;background-repeat: no-repeat;" class="ion-align-self-end errorImage" fxLayout="row" fxLayoutAlign="space-around end" size-lg="12" size-md="12" size-sm="12" size-xs="12">
          <span><mat-icon style="position:relative;top:5px;color:orangered; text-align:center;">warning</mat-icon>&nbsp;&nbsp;Oops! We ran into a problem processing your request.</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-align-self-end" fxLayout="row" fxLayoutAlign="space-around end" size-lg="12" size-md="12" size-sm="12" size-xs="12">
        <span style="text-align: center; display:block">
          <a routerLink="/contact">
            <button mat-raised-button>
              Contact Us
            </button>
          </a>
          </span>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
