<ion-row>
  <ion-col size="12" fxLayout="row" fxLayoutAlign="start">
    <span style="opacity: 0.1">
      {{versionNumber}}
    </span>
  </ion-col>
</ion-row>
<ion-footer class="main-footer">
  <ion-row>
    <ion-col size="12" fxLayout="row" fxLayoutAlign="center start">
      <span>© 2021 - Onken - Who Offers What | <a [routerLink]="[ '/faq' ]">FAQ</a> | <a [routerLink]="[ '/how-it-works' ]">About</a> | <a [routerLink]="[ '/contact' ]">Contact Us</a> | Supported Browsers: Chrome, Firefox, Edge</span>
    </ion-col>
  </ion-row>
</ion-footer>
