import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { SplashLayoutComponent } from './layouts/splash-layout/splash-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { RedirectGuard } from './helpers/redirect-guard';
import { ErrorComponent } from './components/error/error.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    data: {
      breadcrumb: {
        label: 'Home',
        info: 'home',
      }
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./Modules/Main.module').then(m => m.MainModule),
      },
    ]
  },
  {
    path:'',
    component: SplashLayoutComponent,
    children:[
      {
        path: '',
        loadChildren: () => import('./Modules/Home.module').then(m => m.HomeModule),
      },
      {
        path: '',
        loadChildren: () => import('./Modules/LoginRegister.module').then(m => m.LoginRegisterModule),
      }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./Modules/Main.module').then(m => m.MainModule),
      },
      {
        path: '',
        loadChildren: () => import('./Modules/Configuration.module').then(m => m.ConfigurationModule),
      },
      {
        path: '',
        loadChildren: () => import('./Modules/How-It-Works.module').then(m => m.HowItWorksModule),
      },
      {
        path: '',
        loadChildren: () => import('./Modules/Faq.module').then(m => m.FaqModule),
      },
      {
        path: '',
        loadChildren: () => import('./Modules/Users.module').then(m => m.UsersModule),
      },
      {
        path: '',
        loadChildren: () => import('./Modules/Contact.module').then(m => m.ContactModule),
      },
      // { path: 'contact', component: ContactComponent, data: {breadcrumb: { name: 'Contact', skip: true }}},
      {
        path: '',
        loadChildren: () => import('./Modules/Search-Page.module').then(m => m.SearchPageModule),
      },
      // { path: 'search', component: SearchPageComponent, data: {breadcrumb: { name: 'Search', skip: true }}},
      {
        path: '',
        loadChildren: () => import('./Modules/Map-Page.module').then(m => m.MapPageModule),
      },
      // { path: 'map', component: MapPageComponent, data: {breadcrumb: { name: 'Map', skip: true }}},
      {
        path: '',
        loadChildren: () => import('./Modules/Nearby.module').then(m => m.NearbyModule),
      },
      // { path: 'nearby/:id', component: NearbyComponent, data: {breadcrumb: { name: 'Nearby', skip: true }}},
      {
        path: '',
        loadChildren: () => import('./Modules/Favorites.module').then(m => m.FavoritesModule),
      },
      // { path: 'favorites', component: FavoritesComponent, data: {breadcrumb: { name: 'Favorites', skip: true }}},
      {
        path: '',
        loadChildren: () => import('./Modules/Abuse-Manager.module').then(m => m.AbuseManagerModule),
      },
      // { path: 'abuse-manager', component: AbuseManagerComponent, data: {breadcrumb: { name: 'Abuse Manager', skip: true }}},
      {
        path: '',
        loadChildren: () => import('./Modules/Account.module').then(m => m.AccountModule),
      },
      // { path: 'account', component: AccountComponent, data: {breadcrumb: { name: 'My Account', skip: true }}},

      {
        path: '',
        loadChildren: () => import('./Modules/Category.module').then(m => m.CategoryModule),
      },
      // {
      //   path: 'category/:id',
      //   canActivateChild: [ActivateChildGuard],
      //   component: CategoryComponent,
      //   children: [
      //     {path: '', component: CategoryComponent},
      //     {path: 'subcategory/:id2', component: CategoryComponent},
      //   ]
      // },
      {
        path: '',
        loadChildren: () => import('./Modules/Post.module').then(m => m.PostModule),
      },
      // { path: 'post/:id', component: PostComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Profile.module').then(m => m.ProfileModule),
      },
      // { path: 'profile/:id', component: ProfileComponent },
      { path: 'error', component: ErrorComponent },
      { path: 'notfound', component: NotfoundComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },

      {
        path: '',
        loadChildren: () => import('./Modules/Review-Post.module').then(m => m.ReviewPostModule),
      },
      // { path: 'review-post/:id', component: ReviewPostComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Review-Cart.module').then(m => m.ReviewCartModule),
      },
      {
        path: '',
        loadChildren: () => import('./Modules/Review-Receipts.module').then(m => m.ReviewReceiptsModule),
      },
      // { path: 'review-receipts/:id', component: ReviewReceiptsComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Verification.module').then(m => m.VerificationModule),
      },
      // { path: 'verification/:id', component: VerificationComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Reset-Password.module').then(m => m.ResetPasswordModule),
      },
      // { path: 'reset-password/:id', component: ResetPasswordComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Create-Post.module').then(m => m.CreatePostModule),
      },
      // { path: 'create-post', component: CreatePostComponent},
      {
        path: '',
        loadChildren: () => import('./Modules/Post-Editor.module').then(m => m.PostEditorModule),
      },
      // { path: 'edit-post/:id', component: PostEditorComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Cart.module').then(m => m.CartModule),
      },
      // { path: 'cart', component: CartComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Billing.module').then(m => m.BillingModule),
      },
      // { path: 'billing', component: BillingComponent },
      {
        path: '',
        loadChildren: () => import('./Modules/Checkout.module').then(m => m.CheckoutModule),
      },
      // { path: 'checkout', component: CheckoutComponent},
      {
        path: '',
        loadChildren: () => import('./Modules/Chat.module').then(m => m.ChatModule),
      },
      // { path: 'app-chat', component: ChatComponent },
      {
        path: 'swagger',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: '/swagger/index.html'
        }
      },
      {
        path: 'hangfire',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: '/hangfire'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
