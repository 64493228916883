import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {

  remainingPercent:number;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              public snackBar: MatSnackBar) {

  }  

  ngOnInit() {
    let startTime = this.data.duration;
    let remainingTime = this.data.duration;
    let interval = setInterval(() => {
      remainingTime = remainingTime - 100;    
      this.remainingPercent = remainingTime/startTime * 100;                           
      if(remainingTime === 0) {
        clearInterval(interval);
      }
  }, 100)

  }

}
