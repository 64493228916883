/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FaqEntity } from '../models/faq-entity';
@Injectable({
  providedIn: 'root',
})
class FaqService extends __BaseService {
  static readonly getApiFaqPath = '/api/faq';
  static readonly putApiFaqPath = '/api/faq';
  static readonly deleteApiFaqPath = '/api/faq';
  static readonly postApiFaqAddnewfaqasyncPath = '/api/faq/addnewfaqasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return FAQ
   */
  getApiFaqResponse(apiVersion?: string): __Observable<__StrictHttpResponse<FaqEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/faq`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FaqEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return FAQ
   */
  getApiFaq(apiVersion?: string): __Observable<FaqEntity> {
    return this.getApiFaqResponse(apiVersion).pipe(
      __map(_r => _r.body as FaqEntity)
    );
  }

  /**
   * @param params The `FaqService.PutApiFaqParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiFaqResponse(params: FaqService.PutApiFaqParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/faq`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `FaqService.PutApiFaqParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiFaq(params: FaqService.PutApiFaqParams): __Observable<null> {
    return this.putApiFaqResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `FaqService.DeleteApiFaqParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiFaqResponse(params: FaqService.DeleteApiFaqParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/faq`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `FaqService.DeleteApiFaqParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiFaq(params: FaqService.DeleteApiFaqParams): __Observable<null> {
    return this.deleteApiFaqResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `FaqService.PostApiFaqAddnewfaqasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New FAQ
   */
  postApiFaqAddnewfaqasyncResponse(params: FaqService.PostApiFaqAddnewfaqasyncParams): __Observable<__StrictHttpResponse<FaqEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/faq/addnewfaqasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FaqEntity>;
      })
    );
  }
  /**
   * @param params The `FaqService.PostApiFaqAddnewfaqasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New FAQ
   */
  postApiFaqAddnewfaqasync(params: FaqService.PostApiFaqAddnewfaqasyncParams): __Observable<FaqEntity> {
    return this.postApiFaqAddnewfaqasyncResponse(params).pipe(
      __map(_r => _r.body as FaqEntity)
    );
  }
}

module FaqService {

  /**
   * Parameters for putApiFaq
   */
  export interface PutApiFaqParams {
    body?: FaqEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiFaq
   */
  export interface DeleteApiFaqParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiFaqAddnewfaqasync
   */
  export interface PostApiFaqAddnewfaqasyncParams {
    body?: FaqEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { FaqService }
