/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HowItWorksEntity } from '../models/how-it-works-entity';
@Injectable({
  providedIn: 'root',
})
class HowItWorksService extends __BaseService {
  static readonly getApiHowitworksPath = '/api/howitworks';
  static readonly putApiHowitworksPath = '/api/howitworks';
  static readonly deleteApiHowitworksPath = '/api/howitworks';
  static readonly postApiHowitworksAddnewhowitworksasyncPath = '/api/howitworks/addnewhowitworksasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return HowItWorks
   */
  getApiHowitworksResponse(apiVersion?: string): __Observable<__StrictHttpResponse<HowItWorksEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/howitworks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HowItWorksEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return HowItWorks
   */
  getApiHowitworks(apiVersion?: string): __Observable<HowItWorksEntity> {
    return this.getApiHowitworksResponse(apiVersion).pipe(
      __map(_r => _r.body as HowItWorksEntity)
    );
  }

  /**
   * @param params The `HowItWorksService.PutApiHowitworksParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiHowitworksResponse(params: HowItWorksService.PutApiHowitworksParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/howitworks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `HowItWorksService.PutApiHowitworksParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiHowitworks(params: HowItWorksService.PutApiHowitworksParams): __Observable<null> {
    return this.putApiHowitworksResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `HowItWorksService.DeleteApiHowitworksParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiHowitworksResponse(params: HowItWorksService.DeleteApiHowitworksParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/howitworks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `HowItWorksService.DeleteApiHowitworksParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiHowitworks(params: HowItWorksService.DeleteApiHowitworksParams): __Observable<null> {
    return this.deleteApiHowitworksResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `HowItWorksService.PostApiHowitworksAddnewhowitworksasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New HowItWorks
   */
  postApiHowitworksAddnewhowitworksasyncResponse(params: HowItWorksService.PostApiHowitworksAddnewhowitworksasyncParams): __Observable<__StrictHttpResponse<HowItWorksEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/howitworks/addnewhowitworksasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HowItWorksEntity>;
      })
    );
  }
  /**
   * @param params The `HowItWorksService.PostApiHowitworksAddnewhowitworksasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add New HowItWorks
   */
  postApiHowitworksAddnewhowitworksasync(params: HowItWorksService.PostApiHowitworksAddnewhowitworksasyncParams): __Observable<HowItWorksEntity> {
    return this.postApiHowitworksAddnewhowitworksasyncResponse(params).pipe(
      __map(_r => _r.body as HowItWorksEntity)
    );
  }
}

module HowItWorksService {

  /**
   * Parameters for putApiHowitworks
   */
  export interface PutApiHowitworksParams {
    body?: HowItWorksEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiHowitworks
   */
  export interface DeleteApiHowitworksParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for postApiHowitworksAddnewhowitworksasync
   */
  export interface PostApiHowitworksAddnewhowitworksasyncParams {
    body?: HowItWorksEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { HowItWorksService }
