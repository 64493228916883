/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Rootobject } from '../models/rootobject';
@Injectable({
  providedIn: 'root',
})
class GeolocationService extends __BaseService {
  static readonly getApiGeolocationGeocoderequestasyncPath = '/api/geolocation/geocoderequestasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `GeolocationService.GetApiGeolocationGeocoderequestasyncParams` containing the following parameters:
   *
   * - `latlng`:
   *
   * - `api-version`: The requested API version
   *
   * - `address`:
   *
   * @return The resource with the specified unique identifier.
   */
  getApiGeolocationGeocoderequestasyncResponse(params: GeolocationService.GetApiGeolocationGeocoderequestasyncParams): __Observable<__StrictHttpResponse<Rootobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.latlng != null) __params = __params.set('latlng', params.latlng.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.address != null) __params = __params.set('address', params.address.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/geolocation/geocoderequestasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Rootobject>;
      })
    );
  }
  /**
   * @param params The `GeolocationService.GetApiGeolocationGeocoderequestasyncParams` containing the following parameters:
   *
   * - `latlng`:
   *
   * - `api-version`: The requested API version
   *
   * - `address`:
   *
   * @return The resource with the specified unique identifier.
   */
  getApiGeolocationGeocoderequestasync(params: GeolocationService.GetApiGeolocationGeocoderequestasyncParams): __Observable<Rootobject> {
    return this.getApiGeolocationGeocoderequestasyncResponse(params).pipe(
      __map(_r => _r.body as Rootobject)
    );
  }
}

module GeolocationService {

  /**
   * Parameters for getApiGeolocationGeocoderequestasync
   */
  export interface GetApiGeolocationGeocoderequestasyncParams {
    latlng?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
    address?: string;
  }
}

export { GeolocationService }
