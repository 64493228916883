/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CouponCodeEntity } from '../models/coupon-code-entity';
import { DiscountTypeEntity } from '../models/discount-type-entity';
import { CouponManagerDto } from '../models/coupon-manager-dto';
@Injectable({
  providedIn: 'root',
})
class DiscountCouponService extends __BaseService {
  static readonly getApiDiscountcouponGetdiscountbycodeasyncPath = '/api/discountcoupon/getdiscountbycodeasync';
  static readonly getApiDiscountcouponGeneratediscountcouponsasyncPath = '/api/discountcoupon/generatediscountcouponsasync';
  static readonly getApiDiscountcouponGetalldiscountcouponsasyncPath = '/api/discountcoupon/getalldiscountcouponsasync';
  static readonly getApiDiscountcouponGetfreediscountcouponasyncPath = '/api/discountcoupon/getfreediscountcouponasync';
  static readonly getApiDiscountcouponGetdiscounttypesasyncPath = '/api/discountcoupon/getdiscounttypesasync';
  static readonly getApiDiscountcouponBuildcouponmanagerdataasyncPath = '/api/discountcoupon/buildcouponmanagerdataasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiscountCouponService.GetApiDiscountcouponGetdiscountbycodeasyncParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Discount From Code
   */
  getApiDiscountcouponGetdiscountbycodeasyncResponse(params: DiscountCouponService.GetApiDiscountcouponGetdiscountbycodeasyncParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.code != null) __params = __params.set('code', params.code.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/discountcoupon/getdiscountbycodeasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `DiscountCouponService.GetApiDiscountcouponGetdiscountbycodeasyncParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `api-version`: The requested API version
   *
   * @return Getting Discount From Code
   */
  getApiDiscountcouponGetdiscountbycodeasync(params: DiscountCouponService.GetApiDiscountcouponGetdiscountbycodeasyncParams): __Observable<number> {
    return this.getApiDiscountcouponGetdiscountbycodeasyncResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `DiscountCouponService.GetApiDiscountcouponGeneratediscountcouponsasyncParams` containing the following parameters:
   *
   * - `weeksValid`:
   *
   * - `discountType`:
   *
   * - `discountChoice`:
   *
   * - `discount`:
   *
   * - `api-version`: The requested API version
   *
   * - `amountOfCoupons`:
   *
   * @return Generating Coupons
   */
  getApiDiscountcouponGeneratediscountcouponsasyncResponse(params: DiscountCouponService.GetApiDiscountcouponGeneratediscountcouponsasyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.weeksValid != null) __params = __params.set('weeksValid', params.weeksValid.toString());
    if (params.discountType != null) __params = __params.set('discountType', params.discountType.toString());
    if (params.discountChoice != null) __params = __params.set('discountChoice', params.discountChoice.toString());
    if (params.discount != null) __params = __params.set('discount', params.discount.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    if (params.amountOfCoupons != null) __params = __params.set('amountOfCoupons', params.amountOfCoupons.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/discountcoupon/generatediscountcouponsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `DiscountCouponService.GetApiDiscountcouponGeneratediscountcouponsasyncParams` containing the following parameters:
   *
   * - `weeksValid`:
   *
   * - `discountType`:
   *
   * - `discountChoice`:
   *
   * - `discount`:
   *
   * - `api-version`: The requested API version
   *
   * - `amountOfCoupons`:
   *
   * @return Generating Coupons
   */
  getApiDiscountcouponGeneratediscountcouponsasync(params: DiscountCouponService.GetApiDiscountcouponGeneratediscountcouponsasyncParams): __Observable<string> {
    return this.getApiDiscountcouponGeneratediscountcouponsasyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Getting Coupons
   */
  getApiDiscountcouponGetalldiscountcouponsasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<CouponCodeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/discountcoupon/getalldiscountcouponsasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CouponCodeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Getting Coupons
   */
  getApiDiscountcouponGetalldiscountcouponsasync(apiVersion?: string): __Observable<CouponCodeEntity> {
    return this.getApiDiscountcouponGetalldiscountcouponsasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as CouponCodeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Getting Coupon
   */
  getApiDiscountcouponGetfreediscountcouponasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<CouponCodeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/discountcoupon/getfreediscountcouponasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CouponCodeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Getting Coupon
   */
  getApiDiscountcouponGetfreediscountcouponasync(apiVersion?: string): __Observable<CouponCodeEntity> {
    return this.getApiDiscountcouponGetfreediscountcouponasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as CouponCodeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Getting Discount Types
   */
  getApiDiscountcouponGetdiscounttypesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<DiscountTypeEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/discountcoupon/getdiscounttypesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiscountTypeEntity>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Getting Discount Types
   */
  getApiDiscountcouponGetdiscounttypesasync(apiVersion?: string): __Observable<DiscountTypeEntity> {
    return this.getApiDiscountcouponGetdiscounttypesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as DiscountTypeEntity)
    );
  }

  /**
   * @param api-version The requested API version
   * @return Getting Discount Types
   */
  getApiDiscountcouponBuildcouponmanagerdataasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<CouponManagerDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/discountcoupon/buildcouponmanagerdataasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CouponManagerDto>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Getting Discount Types
   */
  getApiDiscountcouponBuildcouponmanagerdataasync(apiVersion?: string): __Observable<CouponManagerDto> {
    return this.getApiDiscountcouponBuildcouponmanagerdataasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as CouponManagerDto)
    );
  }
}

module DiscountCouponService {

  /**
   * Parameters for getApiDiscountcouponGetdiscountbycodeasync
   */
  export interface GetApiDiscountcouponGetdiscountbycodeasyncParams {
    code?: string;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiDiscountcouponGeneratediscountcouponsasync
   */
  export interface GetApiDiscountcouponGeneratediscountcouponsasyncParams {
    weeksValid?: number;
    discountType?: string;
    discountChoice?: string;
    discount?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
    amountOfCoupons?: number;
  }
}

export { DiscountCouponService }
