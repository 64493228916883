/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class PostImageService extends __BaseService {
  static readonly deleteApiPostimagePath = '/api/postimage';
  static readonly getApiPostimageGetpreviewimagebypostidasyncPath = '/api/postimage/getpreviewimagebypostidasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PostImageService.DeleteApiPostimageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiPostimageResponse(params: PostImageService.DeleteApiPostimageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/postimage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostImageService.DeleteApiPostimageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiPostimage(params: PostImageService.DeleteApiPostimageParams): __Observable<null> {
    return this.deleteApiPostimageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PostImageService.GetApiPostimageGetpreviewimagebypostidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostimageGetpreviewimagebypostidasyncResponse(params: PostImageService.GetApiPostimageGetpreviewimagebypostidasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/postimage/getpreviewimagebypostidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PostImageService.GetApiPostimageGetpreviewimagebypostidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiPostimageGetpreviewimagebypostidasync(params: PostImageService.GetApiPostimageGetpreviewimagebypostidasyncParams): __Observable<null> {
    return this.getApiPostimageGetpreviewimagebypostidasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PostImageService {

  /**
   * Parameters for deleteApiPostimage
   */
  export interface DeleteApiPostimageParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiPostimageGetpreviewimagebypostidasync
   */
  export interface GetApiPostimageGetpreviewimagebypostidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { PostImageService }
