import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { ConfigDto, AppInitService } from 'app/ApplicationInit/app-init.service';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  private c: ConfigDto;

  constructor(private router: Router, private config: AppInitService) {
    config.loadConfigurations().subscribe(a => {
        this.c = a;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {      
      window.location.href = this.c.api + route.data['externalUrl'];
      return true;
  }
  
}