/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BoostCartDto } from '../models/boost-cart-dto';
@Injectable({
  providedIn: 'root',
})
class BoostService extends __BaseService {
  static readonly postApiBoostAddboostPath = '/api/boost/addboost';
  static readonly getApiBoostUpdateboostPath = '/api/boost/updateboost';
  static readonly getApiBoostUpdateboos2Path = '/api/boost/updateboos2';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BoostService.PostApiBoostAddboostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `profileId`:
   *
   * - `price`:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add Boost
   */
  postApiBoostAddboostResponse(params: BoostService.PostApiBoostAddboostParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userId != null) __params = __params.set('userId', params.userId.toString());
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.price != null) __params = __params.set('price', params.price.toString());
    if (params.postId != null) __params = __params.set('postId', params.postId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/boost/addboost`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param params The `BoostService.PostApiBoostAddboostParams` containing the following parameters:
   *
   * - `userId`:
   *
   * - `profileId`:
   *
   * - `price`:
   *
   * - `postId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add Boost
   */
  postApiBoostAddboost(params: BoostService.PostApiBoostAddboostParams): __Observable<number> {
    return this.postApiBoostAddboostResponse(params).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param params The `BoostService.GetApiBoostUpdateboostParams` containing the following parameters:
   *
   * - `isPurchased`:
   *
   * - `inCart`:
   *
   * - `boostId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Update Boost
   */
  getApiBoostUpdateboostResponse(params: BoostService.GetApiBoostUpdateboostParams): __Observable<__StrictHttpResponse<BoostCartDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isPurchased != null) __params = __params.set('isPurchased', params.isPurchased.toString());
    if (params.inCart != null) __params = __params.set('inCart', params.inCart.toString());
    if (params.boostId != null) __params = __params.set('boostId', params.boostId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/boost/updateboost`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoostCartDto>;
      })
    );
  }
  /**
   * @param params The `BoostService.GetApiBoostUpdateboostParams` containing the following parameters:
   *
   * - `isPurchased`:
   *
   * - `inCart`:
   *
   * - `boostId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Update Boost
   */
  getApiBoostUpdateboost(params: BoostService.GetApiBoostUpdateboostParams): __Observable<BoostCartDto> {
    return this.getApiBoostUpdateboostResponse(params).pipe(
      __map(_r => _r.body as BoostCartDto)
    );
  }

  /**
   * @param params The `BoostService.GetApiBoostUpdateboos2Params` containing the following parameters:
   *
   * - `price`:
   *
   * - `isPurchased`:
   *
   * - `inCart`:
   *
   * - `boostId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Update Boost2
   */
  getApiBoostUpdateboos2Response(params: BoostService.GetApiBoostUpdateboos2Params): __Observable<__StrictHttpResponse<BoostCartDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.price != null) __params = __params.set('price', params.price.toString());
    if (params.isPurchased != null) __params = __params.set('isPurchased', params.isPurchased.toString());
    if (params.inCart != null) __params = __params.set('inCart', params.inCart.toString());
    if (params.boostId != null) __params = __params.set('boostId', params.boostId.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/boost/updateboos2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BoostCartDto>;
      })
    );
  }
  /**
   * @param params The `BoostService.GetApiBoostUpdateboos2Params` containing the following parameters:
   *
   * - `price`:
   *
   * - `isPurchased`:
   *
   * - `inCart`:
   *
   * - `boostId`:
   *
   * - `api-version`: The requested API version
   *
   * @return Update Boost2
   */
  getApiBoostUpdateboos2(params: BoostService.GetApiBoostUpdateboos2Params): __Observable<BoostCartDto> {
    return this.getApiBoostUpdateboos2Response(params).pipe(
      __map(_r => _r.body as BoostCartDto)
    );
  }
}

module BoostService {

  /**
   * Parameters for postApiBoostAddboost
   */
  export interface PostApiBoostAddboostParams {
    userId?: number;
    profileId?: number;
    price?: number;
    postId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiBoostUpdateboost
   */
  export interface GetApiBoostUpdateboostParams {
    isPurchased?: boolean;
    inCart?: boolean;
    boostId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiBoostUpdateboos2
   */
  export interface GetApiBoostUpdateboos2Params {
    price?: number;
    isPurchased?: boolean;
    inCart?: boolean;
    boostId?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { BoostService }
