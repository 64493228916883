import { Injectable, OnDestroy } from '@angular/core';
import { SnackBarService } from './snackbar.service';
import { RefreshCredentialsService as AuthorizedRequestService } from 'app/services/authorized-request.service';
import { ChatService, NotificationService, UserService } from 'app/api/services';
import { Router } from '@angular/router';
import { MessageEntity, NotificationEntity, UserConversationEntity, UserEntity, UserProfileEntity } from 'app/api/models';
import { Subject } from 'rxjs';
import { profile } from 'console';

@Injectable({
  providedIn: 'root'
})
export class LiveMessengerService implements OnDestroy {

  public isLoading: boolean;
  public isUserOnMessagesPage: boolean;
  public notifications: NotificationEntity[];
  public activeConvoId: number;
  public messages: MessageEntity[];
  public newMessage: MessageEntity = {};
  public convoList: UserConversationEntity[];
  public userProfiles: UserProfileEntity[];
  public senderProfileId: number;
  public recipientProfileId: number;
  public unreadMessages: number;
  public loading = false;
  private unsubscribe = new Subject();
  private style: object;
  private convoExists = false;

  constructor(
    public snackBarService: SnackBarService,
    private messageRoute: Router,
    private authorizedRequestService: AuthorizedRequestService,
    private userService: UserService,
    private chatService: ChatService
  ) {
    this.isLoading = false;
    this.isUserOnMessagesPage = false;
    this.convoList = [];
    this.userProfiles = [];
    this.messages = [{}];
  }

  public async getConvosBySearch(selectedProfileId: number, searchedString: string) {
    if (selectedProfileId !== undefined && searchedString !== undefined && selectedProfileId !== null && searchedString !== null) {
      this.chatService
      .getApiChatGetconvosbysearchasync({ searchString: searchedString, profileId: selectedProfileId }).toPromise()
      .then((data: any) => {
        if (data === undefined || data === null) {
          this.convoList = [];
        }
        else {
          this.convoList = data;
        }
      });
    }
  }

  public async GetUserProfiles() {
    if (this.userService !== undefined && this.userService !== null) {
      const request = () => {
        this.userService.getApiUserGetuserprofilesasync().subscribe((data: any) => {
          this.isLoading = false;
          this.userProfiles = data;
          if (this.senderProfileId === undefined || this.senderProfileId === null || this.senderProfileId === 0) {
            this.senderProfileId = this.userProfiles[0].id;
            this.GetConvos();
          }
        });
      };
      await this.authorizedRequestService.sendAuthorizeRequest(request);
    }
  }

  public async CreateConvo(fromPost: boolean, name: string, senderProfileId: number, recipientProfileId: number) {
    this.activeConvoId = 0;
    this.senderProfileId = senderProfileId;
    this.recipientProfileId = recipientProfileId;
    this.GetConvos();

    if (this.recipientProfileId !== undefined && this.recipientProfileId !== this.senderProfileId && this.convoExists === false) {
      const request = () => {
        this.chatService
        .getApiChatStartconversationasync({ senderProfileId: this.senderProfileId, receiverProfileId: this.recipientProfileId, name: name,  fromPost: fromPost})        
        .subscribe((data: any) => {
            if (this.userProfiles === undefined || !this.userProfiles.length) {
              this.GetUserProfiles();
              this.messageRoute.navigate(['/account']).then((resp: any) => {
                const element: HTMLElement = document.getElementById('auto-trigger') as HTMLElement;
                element.click();
              });
            }
            this.GetConvos();
          });
      };
      await this.authorizedRequestService.sendAuthorizeRequest(request);
    } else {
      this.messageRoute.navigate(['/account']).then((resp: any) => {
        const element: HTMLElement = document.getElementById('auto-trigger') as HTMLElement;
        element.click();
      });
    }
  }

  public async GetConvos() {
    this.messages = [];
    this.convoList = [];
    this.activeConvoId = 0;
    const request = () => {
      this.chatService.getApiChatGetconversationsforuserasync({ profileId: this.senderProfileId }).toPromise()
        .then((data: any) => {
          this.convoList = data;
          if (data !== undefined && data.length &&
            (this.activeConvoId === undefined ||
              this.activeConvoId === null ||
              this.activeConvoId === 0) && this.convoList !== undefined && this.convoList !== null && this.convoList.length && this.convoList[0] !== undefined && this.convoList[0] !== null) {
            this.activeConvoId = this.convoList[0].id;
            this.convoExists = true;
          }
          if (this.convoList !== undefined && this.convoList !== null && this.convoList.length && this.convoList[0] !== undefined && this.convoList[0] !== null) {
            this.GetMessages(this.convoList[0].id);
          }
        });
    };
    await this.authorizedRequestService.sendAuthorizeRequest(request);
    window.setTimeout(function () {
      const element: HTMLElement = document.getElementsByClassName('conversation-item')[0] as HTMLElement;
      if (element !== undefined) {
        element.click();
      }
    }, 500);
  }

  SetStyle(convo) {
    // this is not working...
    if (convo.id === this.activeConvoId) {
      return 'currentItem';
    } else {
      return 'readMessage';
    }
  }

  public async GetMessages(convoId: number) {
    this.messages = [];
    this.activeConvoId = convoId;
    const request = () => {
      this.chatService.getApiChatGetmessagesbyconversationasync({ conversationId: convoId, profileId: this.senderProfileId })
        .subscribe((data: any) => {
          if (data !== undefined && data !== null && data.length) {
            this.messages = data;
          } else {
            this.messages[0] = {
              content: 'Send your first message!'
            } as MessageEntity;
          }
        });
    };
    await this.authorizedRequestService.sendAuthorizeRequest(request);
    this.unreadMessages = 0;
    this.convoList.forEach(convo => {
      this.chatService.getApiChatCountunreadmessagesinconvo({ convoId: convo.id }).toPromise().then((data: any) => {
        convo.unreadMessages = data;
        this.unreadMessages += data;
      });
    });
  }

  public OpenConvo(convoId) {
    if (convoId !== undefined) {
      this.activeConvoId = convoId;
      this.chatService.getApiChatMarkmessagesasreadasync({ convoId: convoId }).toPromise().then((data: any) => {
        this.convoList.forEach(convo => {
          this.chatService.getApiChatCountunreadmessagesinconvo({ convoId: convo.id }).toPromise().then((data: any) => {
            convo.unreadMessages = data;
          });
        });
        this.GetMessages(convoId);
      });
    }
  }

  public async sendMessage() {
    if (this.newMessage.content.trim() === '') {
      return;
    } else {
      const request = () => {
        this.loading = true;
        this.chatService.getApiChatSendmessageasync({
          profileId: this.senderProfileId,
          conversationId: this.activeConvoId,
          message: this.newMessage.content
        }).subscribe(async (data: any) => {
          this.newMessage.content = '';
          await this.GetMessages(this.activeConvoId);
          this.loading = false;
        });
      };
      await this.authorizedRequestService.sendAuthorizeRequest(request);
    }
    this.GetMessages(this.activeConvoId);
  }

  public ClearData() {
    this.userProfiles = undefined;
    this.senderProfileId = undefined;
    this.recipientProfileId = undefined;
    this.notifications = undefined;
    this.activeConvoId = undefined;
    this.messages = undefined;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
