/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SiteImageEntity } from '../models/site-image-entity';
@Injectable({
  providedIn: 'root',
})
class SiteImageService extends __BaseService {
  static readonly postApiSiteimageUploadimageasyncPath = '/api/siteimage/uploadimageasync';
  static readonly deleteApiSiteimagePath = '/api/siteimage';
  static readonly putApiSiteimagePath = '/api/siteimage';
  static readonly getApiSiteimageGetpreviewimagebyidasyncPath = '/api/siteimage/getpreviewimagebyidasync';
  static readonly getApiSiteimageGetallsiteimagesasyncPath = '/api/siteimage/getallsiteimagesasync';
  static readonly getApiSiteimageGetsplashimageasyncPath = '/api/siteimage/getsplashimageasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SiteImageService.PostApiSiteimageUploadimageasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add Images
   */
  postApiSiteimageUploadimageasyncResponse(params: SiteImageService.PostApiSiteimageUploadimageasyncParams): __Observable<__StrictHttpResponse<SiteImageEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/siteimage/uploadimageasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SiteImageEntity>;
      })
    );
  }
  /**
   * @param params The `SiteImageService.PostApiSiteimageUploadimageasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Add Images
   */
  postApiSiteimageUploadimageasync(params: SiteImageService.PostApiSiteimageUploadimageasyncParams): __Observable<SiteImageEntity> {
    return this.postApiSiteimageUploadimageasyncResponse(params).pipe(
      __map(_r => _r.body as SiteImageEntity)
    );
  }

  /**
   * @param params The `SiteImageService.DeleteApiSiteimageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiSiteimageResponse(params: SiteImageService.DeleteApiSiteimageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/siteimage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SiteImageService.DeleteApiSiteimageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  deleteApiSiteimage(params: SiteImageService.DeleteApiSiteimageParams): __Observable<null> {
    return this.deleteApiSiteimageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SiteImageService.PutApiSiteimageParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiSiteimageResponse(params: SiteImageService.PutApiSiteimageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/siteimage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SiteImageService.PutApiSiteimageParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   */
  putApiSiteimage(params: SiteImageService.PutApiSiteimageParams): __Observable<null> {
    return this.putApiSiteimageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SiteImageService.GetApiSiteimageGetpreviewimagebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiSiteimageGetpreviewimagebyidasyncResponse(params: SiteImageService.GetApiSiteimageGetpreviewimagebyidasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/siteimage/getpreviewimagebyidasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SiteImageService.GetApiSiteimageGetpreviewimagebyidasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   */
  getApiSiteimageGetpreviewimagebyidasync(params: SiteImageService.GetApiSiteimageGetpreviewimagebyidasyncParams): __Observable<null> {
    return this.getApiSiteimageGetpreviewimagebyidasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param api-version The requested API version
   */
  getApiSiteimageGetallsiteimagesasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/siteimage/getallsiteimagesasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   */
  getApiSiteimageGetallsiteimagesasync(apiVersion?: string): __Observable<null> {
    return this.getApiSiteimageGetallsiteimagesasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SiteImageService.GetApiSiteimageGetsplashimageasyncParams` containing the following parameters:
   *
   * - `isCanadian`:
   *
   * - `api-version`: The requested API version
   */
  getApiSiteimageGetsplashimageasyncResponse(params: SiteImageService.GetApiSiteimageGetsplashimageasyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.isCanadian != null) __params = __params.set('isCanadian', params.isCanadian.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/siteimage/getsplashimageasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SiteImageService.GetApiSiteimageGetsplashimageasyncParams` containing the following parameters:
   *
   * - `isCanadian`:
   *
   * - `api-version`: The requested API version
   */
  getApiSiteimageGetsplashimageasync(params: SiteImageService.GetApiSiteimageGetsplashimageasyncParams): __Observable<null> {
    return this.getApiSiteimageGetsplashimageasyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SiteImageService {

  /**
   * Parameters for postApiSiteimageUploadimageasync
   */
  export interface PostApiSiteimageUploadimageasyncParams {
    body?: Array<string>;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiSiteimage
   */
  export interface DeleteApiSiteimageParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiSiteimage
   */
  export interface PutApiSiteimageParams {
    body?: SiteImageEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiSiteimageGetpreviewimagebyidasync
   */
  export interface GetApiSiteimageGetpreviewimagebyidasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for getApiSiteimageGetsplashimageasync
   */
  export interface GetApiSiteimageGetsplashimageasyncParams {
    isCanadian?: boolean;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { SiteImageService }
