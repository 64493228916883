/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BillingEntity } from '../models/billing-entity';
@Injectable({
  providedIn: 'root',
})
class BillingService extends __BaseService {
  static readonly getApiBillingGetbillingitemsbyuserasyncPath = '/api/billing/getbillingitemsbyuserasync';
  static readonly postApiBillingAddbillingitemasyncPath = '/api/billing/addbillingitemasync';
  static readonly putApiBillingUpdatebillingitemasyncPath = '/api/billing/updatebillingitemasync';
  static readonly deleteApiBillingDeletebillingitemasyncPath = '/api/billing/deletebillingitemasync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param api-version The requested API version
   * @return Retrieved Billing Data
   */
  getApiBillingGetbillingitemsbyuserasyncResponse(apiVersion?: string): __Observable<__StrictHttpResponse<Array<BillingEntity>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (apiVersion != null) __params = __params.set('api-version', apiVersion.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/billing/getbillingitemsbyuserasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BillingEntity>>;
      })
    );
  }
  /**
   * @param api-version The requested API version
   * @return Retrieved Billing Data
   */
  getApiBillingGetbillingitemsbyuserasync(apiVersion?: string): __Observable<Array<BillingEntity>> {
    return this.getApiBillingGetbillingitemsbyuserasyncResponse(apiVersion).pipe(
      __map(_r => _r.body as Array<BillingEntity>)
    );
  }

  /**
   * @param params The `BillingService.PostApiBillingAddbillingitemasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Saved New Billing
   */
  postApiBillingAddbillingitemasyncResponse(params: BillingService.PostApiBillingAddbillingitemasyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/billing/addbillingitemasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `BillingService.PostApiBillingAddbillingitemasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Saved New Billing
   */
  postApiBillingAddbillingitemasync(params: BillingService.PostApiBillingAddbillingitemasyncParams): __Observable<string> {
    return this.postApiBillingAddbillingitemasyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `BillingService.PutApiBillingUpdatebillingitemasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Updated Billing
   */
  putApiBillingUpdatebillingitemasyncResponse(params: BillingService.PutApiBillingUpdatebillingitemasyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/billing/updatebillingitemasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `BillingService.PutApiBillingUpdatebillingitemasyncParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `api-version`: The requested API version
   *
   * @return Updated Billing
   */
  putApiBillingUpdatebillingitemasync(params: BillingService.PutApiBillingUpdatebillingitemasyncParams): __Observable<string> {
    return this.putApiBillingUpdatebillingitemasyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `BillingService.DeleteApiBillingDeletebillingitemasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Deleted Billing
   */
  deleteApiBillingDeletebillingitemasyncResponse(params: BillingService.DeleteApiBillingDeletebillingitemasyncParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.apiVersion != null) __params = __params.set('api-version', params.apiVersion.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/billing/deletebillingitemasync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `BillingService.DeleteApiBillingDeletebillingitemasyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `api-version`: The requested API version
   *
   * @return Deleted Billing
   */
  deleteApiBillingDeletebillingitemasync(params: BillingService.DeleteApiBillingDeletebillingitemasyncParams): __Observable<string> {
    return this.deleteApiBillingDeletebillingitemasyncResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module BillingService {

  /**
   * Parameters for postApiBillingAddbillingitemasync
   */
  export interface PostApiBillingAddbillingitemasyncParams {
    body?: BillingEntity;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for putApiBillingUpdatebillingitemasync
   */
  export interface PutApiBillingUpdatebillingitemasyncParams {
    body?: Array<BillingEntity>;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }

  /**
   * Parameters for deleteApiBillingDeletebillingitemasync
   */
  export interface DeleteApiBillingDeletebillingitemasyncParams {
    id?: number;

    /**
     * The requested API version
     */
    apiVersion?: string;
  }
}

export { BillingService }
